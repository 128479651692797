import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';



import { ServicesService } from '../../shared/services/services.service';
import { LoginUserComponent } from '../../pages/login/login-user/login-user.component';
import { LoginAdminComponent } from '../../pages/login/login-admin/login-admin.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss']
})
export class SubmenuComponent implements OnInit {

  public configuracion: any;
  public cartProducts = {
    number: 0,
    total: 0.00
  }
  public closeResult: string;
  public loadingAll = false;
  public clientLogin = {
    name: '',
    imagen: '',
    login: false,
    rol: ''
  }
  public companyNane;
  public searchProd = '';
  public textWhatsapp = '';
  
  urlRuta = environment.firebaseUrl;

  logo2:any;
  urlLogo2:any
  information:any=[];

  constructor(
    private webService: ServicesService,
    private modalCtrl: NgbModal,
    private toaster: ToastrService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.urlRuta;
    this.loadingAll = true;
    await this.getConfiguracion();
    await this.getInformacion();
    await this.webService.observableLoginUser().subscribe((resLog: any) => {
      this.clientLogin = {
        name: resLog.name,
        imagen: resLog.imagen,
        login: resLog.login,
        rol: resLog.rol
      }
      // console.log("suscritoo", this.clientLogin);
    });
    // Suscribirse a cambios del carrito
    await this.webService.observableProductsCart().subscribe((rescart: any) => {
      // console.log("suscrito car", rescart);
      this.cartProducts = {
        number: rescart.number,
        total: rescart.total
      }
    });
    this.loadingAll = false;
  }

  formBusqueda = new FormGroup({
    campoText: new FormControl('', Validators.minLength(3))

  })

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === ModalDismissReasons) {
      return `with: ${reason}`;
    }
  }

  goInicio(){
    // console.log("ENTRP");
    
    this.router.navigateByUrl('/inicio')
    // this.scrollToElement('secAboutUS')
  }
  scrollToElement($element:any): void {
    // console.log($element);
    setTimeout(() => {
      $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      // this.listarTodos1();    
     }, 300);
  }

  async getConfiguracion() {
    await this.webService.getConfiguracion().then(async (data: any) => {
      // console.log('data', data);
      
      this.configuracion = data[0];
      // console.log('conf', this.configuracion);
      

      let dsm = document.getElementById('div-submenu');
      // dsm.style.background = this.configuracion.colorPrincipal;
      // dsm.style.color = this.configuracion.colorLetra;

      // Boton Search
      // let bs = document.getElementById('btn-search');
      // bs.style.background = this.configuracion.colorPrincipal;
      // bs.style.color = this.configuracion.colorLetra;

      // Boton Carrito
      let bc = document.getElementById('btn-car');
      // bc.style.background = this.configuracion.colorPrincipal;
      // bc.style.color = this.configuracion.colorLetra;

      //NAVBAR 
      let navbar = document.getElementById('navBarC');
      // navbar.style.background = this.configuracion.colorLetra;
      // navbar.style.color = this.configuracion.colorLetra;

      //NAVBAR 
      let bs2 = document.getElementById('btn-search2');
      // bs2.style.background = this.configuracion.colorPrincipal;
      // bs2.style.color = this.configuracion.colorPrincipal;
      //NAVBAR 
      let   opNav = document.getElementById('optionNav');
      // opNav.style.color = '#666666'
      let   opNav1 = document.getElementById('optionNav1');
      // opNav1.style.color = this.configuracion.colorLetra;
      // opNav1.style.color = '#666666';
      let   opNav2 = document.getElementById('optionNav2');
      // opNav2.style.color = '#666666'
  
      let   opNav3 = document.getElementById('optionNav3');
      // opNav3.style.color = '#666666'
      // let   opNav4 = document.getElementById('optionNav4');
      // opNav4.style.color = '#666666'
      let   opNav5 = document.getElementById('optionNav5');
      // opNav5.style.color = '#666666'
      let   btnLupa = document.getElementById('btnLupa');
      // btnLupa.style.background = this.configuracion.colorPrincipal;
      // btnLupa.style.color = this.configuracion.colorLetra;

      await this.isAutenticatedClient(this.configuracion).then(async (resauth: any) => {
        // Obtener productos del carrito y su total
        if (resauth.rta == true) {
          await this.webService.getproductsCart({ id_cliente: resauth.data.PersonaComercio_cedulaRuc }).then(async (resprod: any) => {
            this.cartProducts = {
              number: resprod.data.length,
              total: await this.webService.calculateTotalCartProducts(resprod.data)
            }
          });
        }
      });
    });
  }

  async getInformacion() {
    this.webService.getInformacion().then((resinfo: any) => {
      this.information=resinfo[0];
      this.logo2  =  resinfo[0]?.logo_dos
      this.urlLogo2 =resinfo[0]?.redirect_logo_dos;
      this.companyNane = resinfo[0]?.nombre;
  
    });
  }

  async loginUser() {
    this.modalCtrl.open(LoginUserComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      // console.log(reason);
    });
  }

  async loginAdmin() {
    this.modalCtrl.open(LoginAdminComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      // console.log(reason);
    });
  }

  async isAutenticatedClient(configuracion) {
    let auth;
    await this.webService.isAuthenticatedClient(configuracion.loginStorage).then((login: any) => {
      auth = login;
      // console.log(auth);
      
      if (login.rta == true) {
        this.clientLogin = {
          name: login.data.nameUser,
          imagen: login.data.imagen,
          login: true,
          rol: login.data.rol
        }
      } else {
        this.clientLogin = {
          name: '',
          imagen: '',
          login: false,
          rol: ''
        }
      }
    });
    return auth;
  }

  async signOff() {
    await this.webService.signOuth(this.configuracion.loginStorage).then((resClose: any) => { });
    this.cartProducts = {
      number: 0,
      total: 0.00
    }
    await this.webService.goHome();
    await this.webService.refreshPage(this.configuracion);
  }

  async goClientProfile() {
    await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then(async (reslogin: any) => {
      if (reslogin.rta == true) {
        if (reslogin.data.rol == 'Client') {
          this.webService.goUserProfile();
          // console.log("ar al cliente");
        }
        if (reslogin.data.rol == 'Administrator') {
          this.webService.goAdminProfile();
          // console.log("ar al administrador");
        }
      }
    });
  }

  async searchProduct(search:any) {
    let s = search.campoText
    // console.log('lo que llega', search);
    
    let type = 'search';
    this.router.navigateByUrl('product/' + type + '/' + s + '/' + 0);
  }

  // async goHome() {
  //   // this.webService.goHomeClean();
  //   this.webService.goHome();
  // }

  async goHome() {
    this.webService.goHome();
    // window.open('https://www.ruedasygarruchas.com/', "_self");
    // window.open('http://publigorras.com.ec', "_self");
}
  async goHome1() {
    // this.webService.goHome();
    // window.open('https://www.ruedasygarruchas.com/', "_self");
    // window.open('http://publigorras.com.ec', "_self");
}


  async goProductsCatalogue() {
    // console.log("AQUI");
    
    this.webService.goProductsCatalogue();
  }
  async goProductsCatalogue1() {
    // console.log("AQUI");
    this.router.navigateByUrl('/products')
    // this.webService.goProducts1();
  
  }

  async goShoppingCartUser() {
    await this.isAutenticatedClient(this.configuracion).then(async (resauth: any) => {
      if (resauth.rta == true) {
        if (this.cartProducts.number > 0) {
          await this.webService.goShoppingCart();
        } else {
          this.toaster.warning('Su carrito de compras esta vacio', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      } else {
        await this.loginUser();
      }
    });
  }

  async contactWhatsapp(whatsappModal) {
    this.textWhatsapp = '';
    this.modalCtrl.open(whatsappModal, { ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'modal-whatsapp' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      if (this.textWhatsapp) {
        this.webService.getInformacion().then((datainfo: any) => {
          window.open("https://wa.me/" + datainfo[0].whatsapp + "?text=" + this.textWhatsapp + ".", "_blank");
        });
      } else {
        this.toaster.warning('Ingrese el texto del mensaje', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  async goAboutUs() {
    await this.webService.goAboutUs();
  }

  redirectULR2(){
        window.open(this.urlLogo2, "_blank");

  }


}
