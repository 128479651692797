<nav class="navbar navbar-expand-lg navbar-light bg-light" id="menu-responsive">

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fas fa-bars"></i>
    </button>

    <span class="float-right text-minuscula-texto">Menú</span>

    <div class="collapse navbar-collapse text-mayuscula" id="navbarNav">
        <ul class="navbar-nav main-navbar-responsivo mr-auto text-size-14 w-100 mt-3">

            <li class="nav-item li-menu tipo-cursor">
                <a class="nav-link" (click)="goHome()">
                    <i class="fas fa-home icon-menu"></i>
                    -
                    <span>{{menu.home}}</span>
                </a>
            </li>

            <li class="nav-item li-menu tipo-cursor">
                <a class="nav-link" (click)="goProductsCatalogue()">
                    <i class="fas fa-gifts icon-menu"></i>
                    -
                    <span>{{menu.catalogo}}</span>
                </a>
            </li>

            <li class="nav-item li-menu tipo-cursor">
                <a class="nav-link" (click)="goAboutUs()">
                    <i class="fas fa-info icon-menu"></i>
                    -
                    <span>{{menu.sobre_nosotros}}</span>
                </a>
            </li>

            <li class="nav-item li-menu tipo-cursor" *ngIf="information.blog">
                <a class="nav-link" target="_blank" (click)="goBlog()">
                    <i class="fas fa-link icon-menu"></i>
                    -
                    <span>{{menu.blog}}</span>
                </a>
            </li>

            <li class="nav-item li-menu tipo-cursor" *ngIf="viewFilter">
                <select class="form-control form-control-sm text-minuscula text-secondary"
                    (change)="orderByFilter($event.target.value)">
                    <option value="asc">
                        ↓↑&emsp;Menor a Mayor
                    </option>
                    <option value="desc">
                        ↑↓&emsp;Mayor a Menor
                    </option>
                </select>
            </li>

        </ul>

    </div>

</nav>