import { Injectable } from "@angular/core";
import { AppRepository } from "./repositories/app.repository";

@Injectable()
export class AppContext {

    constructor(
        private appRepository: AppRepository,
    ) {

    }

    get Repository(): AppRepository {
        return this.appRepository;
    }
    

}