import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { ServicesService } from '../../shared/services/services.service';


@Component({
  selector: 'app-politics-and-terms',
  templateUrl: './politics-and-terms.component.html',
  styleUrls: ['./politics-and-terms.component.scss']
})
export class PoliticsAndTermsComponent implements OnInit {
  information:any=[];
  @ViewChild('inicio') inicioDiv!: ElementRef;
  @ViewChild('nosotros') nosotrosDiv!: ElementRef;
  @ViewChild('contacto') contactoDiv!: ElementRef;
  public loadingAll = false;
  public viewInformation = {
    politicasPrivacidad: false,
    terminosCondiciones: false,
    politicasEnvioEntrega: false,
    preguntasPlacetopay: false,
    politicaAPP: false,

  }
  public configuracion: any;


  constructor(
    private webService: ServicesService,

  ) { }

  async ngOnInit() {
    await this.getConfiguration();
    await this.getInformacion();
  }
  async getInformacion() {
    await this.webService.getInformacion().then((resinfo: any) => {
      // console.log(resinfo);
      this.information=resinfo[0];
      console.log('information.mision',this.information);
      
      let color  = this.information.colorLetraSlogan;
      let colorLetra  = this.configuracion.colorLetra;
      let colorLetraSecundario  = this.configuracion.colorLetraSecundario;
      console.log('color', color); 
      document.documentElement.style.setProperty('--color-font-portada', color);
      document.documentElement.style.setProperty('--color-letter-primary', colorLetra);
      document.documentElement.style.setProperty('--color-letter-secondary', colorLetraSecundario);
      let urlV = this.webService.obtenerCodigoVideo( this.information.video)
      let urlV2 = this.webService.obtenerCodigoVideo( this.information.youtube)
      let url = 'https://www.youtube.com/embed/'+urlV;    
      let url2 = 'https://www.youtube.com/embed/'+urlV2;    


    });
  }

  async getConfiguration() {
    await this.webService.getConfiguracion().then(async (resconf) => {
      this.configuracion = resconf[0];
      if (resconf[0].terminosCondiciones == 1) {
        await this.selectInformationView(5);
        // Boton Terminos
        let tc = document.getElementById('btn-terminos');
        tc.style.background = this.configuracion.colorPrincipal;
        tc.style.color = this.configuracion.colorLetra;
        // Boton Privacidad
        let pp = document.getElementById('btn-privacidad');
        pp.style.background = this.configuracion.colorPrincipal;
        pp.style.color = this.configuracion.colorLetra;
        // Boton Envio
        let ee = document.getElementById('btn-envio');
        ee.style.background = this.configuracion.colorPrincipal;
        ee.style.color = this.configuracion.colorLetra;
        let eapp = document.getElementById('btn-app');
        eapp.style.background = this.configuracion.colorPrincipal;
        eapp.style.color = this.configuracion.colorLetra;
      } else {
        this.webService.goHome()
      }
    });
  }

  async selectInformationView(identificador) {
    this.viewInformation = {
      politicasPrivacidad: false,
      terminosCondiciones: false,
      politicasEnvioEntrega: false,
      preguntasPlacetopay: false,
      politicaAPP: false,
    }
    switch (identificador) {
      case 1: {
        this.viewInformation.politicasPrivacidad = true;
        break;
      }
      case 2: {
        this.viewInformation.terminosCondiciones = true;
        break;
      }
      case 3: {
        this.viewInformation.politicasEnvioEntrega = true;
        break;
      }
      case 4: {
        this.viewInformation.preguntasPlacetopay = true;
        break;
      }
      case 5: {
        this.viewInformation.politicaAPP = true;
        break;
      }
    }
    // Ir hacia arriba
    await this.webService.goUpPage();
  }

  isEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }

  goSection(event:any){
    console.log('event',event);
    if(event.siteO=='inicio'){ 
      this.scrollToElement(this.inicioDiv.nativeElement)
    }else if(event.siteO=='nosotros'){
      this.scrollToElement(this.nosotrosDiv.nativeElement)
    }else if(event.siteO=='commerce'){

    }else{
      this.scrollToElement(this.contactoDiv.nativeElement)   
    } 
  }

  scrollToElement($element:any): void {
    setTimeout(() => {
      $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
     }, 400);
  }

}
