import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { ServicesService } from '../../shared/services/services.service';


@Component({
  selector: 'app-vaucher',
  templateUrl: './vaucher.component.html',
  styleUrls: ['./vaucher.component.scss']
})
export class VaucherComponent implements OnInit {

  @ViewChild("ModalDatafast", {}) ModalDatafast: TemplateRef<any>;
  @ViewChild("ModalTransactionResult", {}) ModalTransactionResult: TemplateRef<any>;


  public loadingAll = false;
  public vaucher: any = {};
  public configuracion: any = {};
  public dataFast: any = {};
  public closeResult: string;
  public transaction = {
    status: '',
    result: '',
    reference: 0
  }
  constructor(
    private activateRoute: ActivatedRoute,
    public toaster: ToastrService,
    private modalCtrl: NgbModal,
    private elementRef: ElementRef,
    public router: Router,
    private webService: ServicesService
  ) {
  }

  async ngOnInit() {
    this.loadingAll = true;
    await this.webService.getConfiguracion().then(async (resconf: any) => {
      this.configuracion = resconf[0];
    });
    this.loadingAll = false;
    // Obtener respuesta de DataFast o Id vaucher
    let pathDatafast = this.router.url.indexOf('resourcePath');
    if (pathDatafast > 0) {
      console.log("consultar datafast");
      let id_cotization = this.router.url.indexOf('vaucher?id_cotizacion=');
      let all = this.router.url.substring(id_cotization, (this.router.url.length));
      // id_cotizacion
      if (id_cotization > 0) {
        let start = all.indexOf('=');
        let end = all.indexOf('&');
        this.dataFast.id_cotizacion = all.substring(start + 1, (end));
      }
      // Resource Path
      let r = all.replace(/%2F/g, '/');
      let v1 = r.indexOf("v1");
      this.dataFast.path = r.substring(v1, (r.length));
      this.dataFast.rta = true;
    } else {
      console.log("consultar vaucher");
      let id_vaucher;
      let url = this.router.url.indexOf('vaucher?id=');
      let all = this.router.url.substring(url, (this.router.url.length));
      if (url > 0) {
        let start = all.indexOf('=');
        id_vaucher = all.substring(start + 1, (all.length));
        this.loadingAll = true;
        let v = {
          id: id_vaucher,
          id_empresa: this.configuracion.id_empresa,
          estado: 0
        }
        await this.webService.searchVaucher(v).then(async (ressear: any) => {
          this.loadingAll = false;
          if (ressear.rta) {
            this.vaucher = ressear.data[0];
          } else {
            this.toaster.error('Transacción no encontrada, Comuníquese con el Administrador', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        });
      }
    }
    // Comprobar estado de transaccion
    if (this.dataFast.rta == true) {
      this.loadingAll = true;
      let datafastData;
      // Obtener credenciales de datafast
      await this.webService.getPaymentButtons().then((res: any) => {
        for (let e of res) {
          if (e.nombre == "DataFast") {
            datafastData = e;
          }
        }
      });
      // Ver estado de transaccion
      await this.webService.getStatusDatafast(this.dataFast.path, datafastData).then(async (rescheck: any) => {
        console.log("rescheck", rescheck);
        this.transaction.reference = this.dataFast.id_cotizacion;
        if (rescheck.rta == true) {
          let code = rescheck.res.result.code;
          if (code == '000.100.112' || code == '000.000.000') {
            let v = {
              id: this.dataFast.id_cotizacion,
              id_empresa: this.configuracion.id_empresa,
              estado: 0
            }
            // Buscar Vaucher y actualizar estado
            await this.webService.searchVaucher(v).then(async (ressear: any) => {
              if (ressear.rta) {
                ressear.data[0].estado = 1;
                await this.webService.getCurrentDate().then((resdate: any) => {
                  ressear.data[0].fecha_transaccion = resdate.year + '-' + resdate.month_number + '-' + resdate.day_number;
                  ressear.data[0].hora_transaccion = resdate.hour + ':' + resdate.minute + ':' + resdate.second;
                });
                await this.webService.updateClientVaucher(ressear.data[0]).then((resupd: any) => { });
              }
            });
            this.transaction.result = 'La transacción se ha realizado con éxito. (' + rescheck.res.result.description + ')';
            this.transaction.status = 'Accepted';
          } else {
            this.transaction.status = 'Rejected';
            this.transaction.result = 'La transacción ha sido rechazada, Intente nuevamente. (' + rescheck.res.result.description + ')';
          }
        } else {
          this.transaction.status = 'Rejected';
          this.transaction.result = 'La transacción ha sido rechazada, Intente nuevamente';
        }
        this.TransactionResultModal(this.transaction);
      });
      this.loadingAll = false;
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  async payDatafast() {
    if (this.vaucher.nombres && this.vaucher.nombres) {
      this.loadingAll = true;
      let entity = "DataFast";
      let btnDataFast;
      let information;
      let customerId = this.vaucher.id + 'cli' + this.configuracion.id_empresa;
      let aux = '';
      for (var i = customerId.length; i < 10; i++) {
        aux += '0';
      }
      let deliveryData = {
        province: {},
        canton: {},
        infoSend: {}
      }
      let product = [{
        pro_nom: this.vaucher.detalle_transaccion,
        value_final: this.vaucher.valor_transaccion,
        precio_sin_iva: this.vaucher.valor_transaccion,
        impuesto_porcent: 0,
        precioReal: this.vaucher.valor_transaccion,
        quantity: 1
      }];
      // Obtener credenciales de datafast
      await this.webService.getPaymentButtons().then((res: any) => {
        for (let e of res) {
          if (e.nombre == entity) {
            btnDataFast = e;
          }
        }
      });
      // Obtener informacion de empresa
      await this.webService.getInformacion().then((resinfo: any) => {
        information = resinfo[0];
      });
      // Asignar dato cliente
      let client = {
        direccion: information.direccion,
        referencia_domicilio: 'S/N',
        PersonaComercio_cedulaRuc: aux + '' + customerId
      }
      // Obtener valores de recargos y descuentos
      await this.webService.determinateArancelsAndDiscounts(1, this.configuracion, deliveryData).then(async (resarancel: any) => {
        resarancel.costoEnvio.data.local = {
          selected: false,
          value: 0
        }
        resarancel.costoEnvio.data.nacional = {
          selected: false,
          value: 0
        }
        await this.webService.calculateSeparateSubtotal(resarancel, product).then(async (ressubtot: any) => {
          ressubtot.ivaPorcent = product[0].impuesto_porcent;
          resarancel.subtotalAux = 0;
          resarancel.calculos = ressubtot;
          // Crear transaccion en datafas
          await this.webService.getPaymentIdentifierDatafast(btnDataFast, client, this.vaucher.id, product, resarancel).then(async (rescheck: any) => {
            // console.log("rescheck", rescheck);
            this.loadingAll = false;
            if (rescheck.rta == true) {
              this.dataFast = {
                rta: false,
                id: rescheck.data.id,
                dominio: this.configuracion.dominioPagina + '/vaucher?id_cotizacion=' + this.vaucher.id,
                id_cotizacion: '',
                path: ''
              }
              // console.log(this.dataFast);
              var script = document.createElement("script");
              script.type = "text/javascript";
              script.src = btnDataFast.url + 'v1/paymentWidgets.js?checkoutId=' + this.dataFast.id;
              // document.onmouseout = function () {
              //   var holder = (<HTMLInputElement>document.getElementsByClassName('wpwl-control-cardHolder')[0]).value;
              //   var button = (<HTMLInputElement>document.getElementsByClassName('wpwl-button-pay')[0]).disabled;
              //   if (holder && button == false) {
              //     (<HTMLInputElement>document.getElementsByClassName('wpwl-button-pay')[0]).disabled = false;
              //     (<HTMLInputElement>document.getElementsByClassName('wpwl-button-pay')[0]).style.backgroundColor = '#5cb85c';
              //     (<HTMLInputElement>document.getElementsByClassName('wpwl-button-pay')[0]).style.borderColor = '#4cae4c';

              //     (<HTMLInputElement>document.getElementsByClassName('wpwl-label-cardHolder')[0]).style.color = '#fff';
              //     (<HTMLInputElement>document.getElementsByClassName('wpwl-label-cardHolder')[0]).innerHTML = "Card holder";

              //     (<HTMLInputElement>document.getElementsByClassName('wpwl-control-cardHolder')[0]).style.border = '1px Solid #ccc';
              //   } else {
              //     if (!holder) {
              //       (<HTMLInputElement>document.getElementsByClassName('wpwl-button-pay')[0]).disabled = true;
              //       (<HTMLInputElement>document.getElementsByClassName('wpwl-button-pay')[0]).style.backgroundColor = '#d9534f';
              //       (<HTMLInputElement>document.getElementsByClassName('wpwl-button-pay')[0]).style.borderColor = '#d43f3a';

              //       (<HTMLInputElement>document.getElementsByClassName('wpwl-label-cardHolder')[0]).style.color = '#a94442';
              //       (<HTMLInputElement>document.getElementsByClassName('wpwl-label-cardHolder')[0]).innerHTML = "Invalid card holder";

              //       (<HTMLInputElement>document.getElementsByClassName('wpwl-control-cardHolder')[0]).style.border = '1px Solid #a94442';
              //     }
              //   }
              // }
              this.elementRef.nativeElement.appendChild(script);
              await this.webService.updateClientVaucher(this.vaucher).then(async (resupd: any) => {
                if (resupd.rta) {
                  // Open Modal
                  this.modalCtrl.open(this.ModalDatafast, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false }).result.then(async (result) => {
                    this.closeResult = `Closed with: ${result}`;
                    console.log("result", result);
                  }, async (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                    console.log("Cancelar", reason);
                    // Anular la transaccion
                    this.vaucher.estado = 2;
                    await this.webService.updateClientVaucher(this.vaucher).then((resupd: any) => { });
                    this.transaction.status = 'Canceled';
                    this.transaction.result = 'La transacción ha sido cancelada por el cliente';
                    this.TransactionResultModal(this.transaction);
                  });
                } else {
                  this.toaster.warning('Algo ha sucedido, Intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                }
              });
            } else {
              this.toaster.warning('Algo ha sucedido, Intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          });
        });
      });
    } else {
      this.toaster.error('Información no válida, Ingrese los campos solicitados', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async TransactionResultModal(data) {
    this.modalCtrl.dismissAll();
    this.transaction = {
      status: data.status,
      result: data.result,
      reference: data.reference
    }
    this.modalCtrl.open(this.ModalTransactionResult, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      await this.webService.goHome();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    let bro = document.getElementById('btn-result-ok');
    bro.style.background = this.configuracion.colorPrincipal;
    bro.style.color = this.configuracion.colorLetra;
  }

}
