import { Component, OnInit, HostListener, Inject, } from '@angular/core';
import { ServicesService } from '../../shared/services/services.service';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss']
})
export class ScrollToTopComponent implements OnInit {

  windowScrolled: boolean;

  constructor(
    private webService: ServicesService
  ) { }


  async ngOnInit(){
    await this.getConfiguracion();
  }

  @HostListener('window:scroll', [])
  
  onWindowScroll(): void {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.windowScrolled = true;
    }
    else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }

  scrollToTop(): void {
    (function smoothscroll(): void {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  async getConfiguracion() {
    this.webService.getConfiguracion().then(async (data: any) => {
      if (!data.error) {
        // let bsc = document.getElementById('btn-scrolled');
        // bsc.style.background = data[0].colorPrincipal;
        // bsc.style.color = data[0].colorLetra; 
      } 
    });
  }

}
