import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ServicesService } from '../../shared/services/services.service';


@Injectable({
  providedIn: 'root'
})
export class GuardsGuard implements CanActivate {


  public isLogged: boolean;

  constructor(
    private webService: ServicesService,
    private router: Router,
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let login: boolean;
    return this.webService.getConfiguracion().then(async (resconfig: any) => {
      await this.webService.isAuthenticatedClient(resconfig[0].loginStorage).then((reslogin: any) => {
        if (reslogin.rta == true) {
          login = reslogin.rta;
        } else {
          this.router.navigate(['']);
          login = reslogin.rta;
          // console.log();
          
        }
      });
      // console.log("Guards", login);
      return login;
    });
  }

}
