import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';

import Compressor from 'compressorjs';

@Injectable({
  providedIn: 'root'
})
export class MigrationService {
  task: AngularFireUploadTask; // propiedad para almacenar la tarea de carga
  percentage: Observable<number>; // propiedad para mostrar el porcentaje de carga
  constructor(
    private storage: AngularFireStorage,
    private http: HttpClient,
    // private imageCompress: NgxImageCompressService
  ) {
  }

  uploadImageFromUrl(url: string, path: string): Promise<any> {
    const storageRef = this.storage.ref(path);
    console.log('url', storageRef);
    return this.http.get('http://45.76.250.195:8090/'+url, { responseType: 'blob' }).toPromise().then((blob: Blob) => {
      // Carga el objeto Blob a Firebase
      return storageRef.put(blob);
    });
  }

  uploadFromURL( url: string, path: string ) {
  
  }

  async uploadLogoFirebase(file: File, path: string): Promise<string> {
    const ref = this.storage.ref(path);
    const task = await ref.put(file);

    return await task.ref.getDownloadURL();
  }

  // COMPRESS IMG

  async compressFile(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.5,
        success(result:any) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }
  //Cambios PROD

  generateRandomString(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const length = 6;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  };

  cambiarUrlFireBtoDataB(url:any) {
    const splitUrl = url.split("/o/");
    if (splitUrl.length >= 2) {
      return splitUrl[1];
    } else {
      return null;
    }
  };

  async uploadImage( file: File, path: string ): Promise<string> {
    try {
      const ref = this.storage.ref( path );
      const task = await ref.put( file );
      return task.ref.getDownloadURL();
    } catch ( error ) {
      throw error;
    }
  }
}


