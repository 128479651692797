<app-submenu></app-submenu>

<ngx-loading [show]="loadingAll" [config]="{ backdropBorderRadius: '3px',  fullScreenBackdrop: true}" [template]="">
</ngx-loading>

<section class="container-fluid">
  <div class="row pt-2 pb-2 pl-3 pr-3 ">
    <div class="col-lg-4"></div>

    <div class="col-lg-4 p-0 text-minuscula-form">
      <div class="row border rounded mt-1 ml-0 mr-0 bg-light" *ngIf="vaucher.valor_transaccion">
        <div class="col-lg-12 mt-2 mb-2 text-center">

          <div class="input-group mb-3 input-group-sm">
            <div class="input-group-prepend w-25">
              <span class="input-group-text w-100">Valor $</span>
            </div>
            <input type="text" class="form-control" disabled [(ngModel)]="vaucher.valor_transaccion">
          </div>

          <div class="input-group mb-3 input-group-sm">
            <div class="input-group-prepend w-25">
              <span class="input-group-text w-100">Descripción</span>
            </div>
            <input type="text" class="form-control" disabled [(ngModel)]="vaucher.detalle_transaccion">
          </div>

          <div class="input-group mb-3 input-group-sm">
            <div class="input-group-prepend w-25">
              <span class="input-group-text w-100">Nombres <strong class="text-danger ml-1">*</strong></span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="vaucher.nombres">
          </div>

          <div class="input-group mb-3 input-group-sm">
            <div class="input-group-prepend w-25">
              <span class="input-group-text w-100">Apellidos <strong class="text-danger ml-1">*</strong></span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="vaucher.apellidos">
          </div>

          <div class="input-group mb-3 input-group-sm">
            <div class="input-group-prepend w-25">
              <span class="input-group-text w-100">Telefono</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="vaucher.telefono">
          </div>

          <div class="input-group mb-3 input-group-sm">
            <div class="input-group-prepend w-25">
              <span class="input-group-text w-100">Correo</span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="vaucher.correo">
          </div>

          <button type="button" class="btn btn-outline-success btn-sm" (click)="payDatafast()">
            Pagar
            <i class="fa fa-credit-card-alt"></i>
          </button>

        </div>
      </div>

      <div class="row border rounded mt-1 ml-0 mr-0 bg-light" *ngIf="!vaucher.valor_transaccion">
        <div class="col-lg-12 mt-2 mb-2 text-center">
          <img class="mt-5 mb-5 w-75"
            src="https://drive.google.com/uc?export=view&id=1X1L4AvE7SN3O_gIucIkmsc47pMASDdS2">
        </div>
      </div>
    </div>

    <div class="col-lg-4"></div>
  </div>
</section>

<!-- Mostrar DataFast -->
<ng-template #ModalDatafast let-modal>
  <div class="card row text-minuscula bg-datafast">
    <br>
    <div class="col-lg-12 text-center pt-3">
      <img class="w-75 mb-4" src="https://www.datafast.com.ec/images/verified.png" alt="">
      <form id="formDataFast" action="{{dataFast.dominio}}" class="paymentWidgets" data-brands="VISA MASTER AMEX DINERS DISCOVER">
      </form>
    </div>
    <br>
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-3"> </div>
        <div class="col-lg-6">
          <button type="button" class="btn btn-danger text-size-14 btn-sm btn-block" (click)="modal.dismiss('Cancel')">Cancelar Transacción</button>
        </div>
        <div class="col-lg-3"> </div>
      </div>
    </div>
    <br>
  </div>
</ng-template>

<!-- Mostrar resultado de la transaccion -->
<ng-template #ModalTransactionResult let-modal>
  <div class="card row text-minuscula">
      <br>
      <div class="col-lg-12 text-center">
          <h2><i class="fas fa-thumbs-up text-size-20 text-success" *ngIf="transaction.status == 'Accepted'"></i></h2>
          <h2><i class="fas fa-thumbs-down text-size-20 text-warning" *ngIf="transaction.status == 'Rejected'"></i></h2>
          <h2><i class="fas fa-ban text-size-20 text-danger" *ngIf="transaction.status == 'Canceled'"></i></h2>
      </div>

      <br>
      <div class="col-lg-12 text-center">
          <p class="text-size-14">
              {{transaction.result}}
          </p>
      </div>

      <div class="col-lg-12 text-center" *ngIf="transaction.reference != 0">
          <p class="text-size-14 border rounded">
              Número Ref.
              <span class="ml-2"><strong class="text-size-16">{{transaction.reference}}</strong></span>
          </p>
      </div>

      <br>
      <div class="col-lg-12 mb-3">
          <div class="row">
              <div class="col-lg-3"> </div>

              <div class="col-lg-6">
                  <button type="button" class="btn text-size-14 btn-sm btn-block" (click)="modal.close('')"
                      id="btn-result-ok">ok</button>
              </div>

              <div class="col-lg-3"> </div>
          </div>
      </div>
      <br>
  </div>
</ng-template>
