import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/shared/services/services.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
 @Input('configuracion') configuracion :any;
 @Input('information') information :any;
 @Output() returnMsjForm = new EventEmitter<any>();
 flagSelectButton=true;
 public existCliente = false;
 action='login';  
 loginForm:any;
  constructor
  (
    private modalService: NgbModal,
    private webService: ServicesService,
    private toaster: ToastrService,
    private router : Router
  ) 
  {
  }

  ngOnInit(): void {
    let color  = this.configuracion.colorPrincipal;
    document.documentElement.style.setProperty('--dynamic-color', color);
    this.selectLoginDefault('user');
    console.log('this.information', this.information);
    
  }

  closeModal(){
    this.modalService.dismissAll();
  }

  selectLoginDefault(type){
    console.log('da', type);
    
      if(type=='user'){
        this.flagSelectButton=true;
      }else{
        this.flagSelectButton=false;
      }
  }

  formAdmin = new FormGroup({
    usuario: new FormControl('', Validators.required),
    clave: new FormControl('', Validators.required),
  })
  formUser = new FormGroup({
    usuario: new FormControl('', Validators.required),
    clave: new FormControl(''),
  })
  formRegister = new FormGroup({
    nombres: new FormControl('', Validators.required),
    apellidos: new FormControl(''),
    cedula: new FormControl(''),
    razonsocial: new FormControl(''),
    direccion: new FormControl(''),
    telefonos: new FormControl(''),
    email: new FormControl(''),
    celular: new FormControl(''),
    tipoCli: new FormControl('1'),
    vendedor_id: new FormControl(''),
    diasCredito: new FormControl('0'),
    cupo_credito:new FormControl('0'),
    es_pasaporte: new FormControl(0)

  })

async loginSystem(form:any){
    await this.loginAdministrator(form, this.configuracion);
}

async loginSystemUser(form:any){

  if(this.information.esPuntoVenta==0){
    
    // form.clave = form.usuario;
  }else{
    form.clave = form.usuario;
  }
  
  this.loginForm=form
  console.log('imprime', this.loginForm);
  
  this.loginUser(form,'Login')
}

  async loginAdministrator(login, configuracion) {
    this.toaster.info('Espere un momento ...', '', { timeOut: 1000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    await this.webService.loginAdministrator(login).then(async (resadmin: any) => {
      if (resadmin.rta == true) {
        resadmin.data.PersonaComercio_cedulaRuc = resadmin.data.cedula;
        resadmin.data.default_price = configuracion.tipoPrecio;
        await this.webService.saveUserLocalStorage(resadmin.data, configuracion.loginStorage, "Administrator").then(async (resLocal: any) => {
          await this.webService.getproductsCart({ id_cliente: resadmin.data.cedula }).then(async (resprod: any) => {
            if (resprod.rta == true) {
              let observable = {
                number: resprod.data.length,
                total: await this.webService.calculateTotalCartProducts(resprod.data)
              }
              this.webService.shopcart$.next(observable);
            }
          });
          // await this.webService.refreshPage(configuracion);
          this.toaster.success('Bienvenid@, ' + resadmin.data.nombres, '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          localStorage.setItem('isLoged','true');
          // await this.webService.goAdminProfile();
          this.router.navigateByUrl('administrador/datos_generales');

          this.closeModal();
        });
      } else {
        this.toaster.warning('Usuario no encontrado, Registrelo.', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        // this.dismissModal('Registrar');
      }
    });
  }
  async loginUser(login,data){
    if (data == 'Login') {
      let url;
      await this.webService.getUrlEmpresa().then(async (url_billing) => {
        url = url_billing
      });
      if (this.existCliente == true) {
        // this.modalService.dismissAll(data);
        console.log('login', login);       
        await this.loginClient(url, this.configuracion, login, 'login');
      } else {
        console.log("validar");
        await this.validateClientExist(url, login.usuario);

        console.log("vino de validar", this.existCliente);
      }
    }
    if (data == 'Registrar') {
      // this.modalCtrl.dismissAll(data);
      // await this.registerClient();
    }
    if (data == 'Recover') {
      // this.modalCtrl.dismissAll(data);
      // await this.modalRecoverPassword();
    }
    if (data == 'Close') {
      // this.modalCtrl.dismissAll(data);
      this.modalService.dismissAll()
    }
  }

  async loginClient(url_billing, configuracion, login, type) {
    console.log('entra aquí --> ',login);
    
    if (login.usuario && login.clave) {
      if (type == 'login') {
        this.toaster.info('Espere un momento ...', '', { timeOut: 1000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
      await this.webService.loginCliente(url_billing, login, 'login').then(async (resclient: any) => {
        if (!resclient.error) {
          if (resclient.rta == true) {
            await this.webService.saveUserLocalStorage(resclient.data[0], configuracion.loginStorage, "Client").then(async (resauth: any) => {
              await this.webService.getproductsCart({ id_cliente: resauth.data.PersonaComercio_cedulaRuc }).then(async (resprod: any) => {
                if (resprod.rta == true) {
                  let observable = {
                    number: resprod.data.length,
                    total: await this.webService.calculateTotalCartProducts(resprod.data)
                  }
                  this.webService.shopcart$.next(observable);
                }
              });
              localStorage.setItem('isLoged','true');
              await this.webService.refreshPage(configuracion);
              this.toaster.success('Bienvenid@, ' + resclient.data[0].nombres, '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            });
          } else {
            if (resclient.code == 406) {
              this.toaster.warning('Contraseña incorrecta, Intente nuevamente o Proceda a recuperarla', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              // this.dismissModal('Recover');
              // this.modalService.dismissAll('Recover');

            } else {
              this.toaster.warning('Usuario no encontrado, Registrelo.', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              // this.modalService.dismissAll('Registrar');
              this.returnMsjForm.emit('registrar');
            }
          }
        } else {
          this.toaster.error('Ha ocurrido un error, Intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    } else {
      this.toaster.warning('Ingrese sus credenciales', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async validateClientExist(url, cedula) {
    // this.loadingAll = true;
    await this.webService.getCustomerDataByCedula(url, cedula).then(async (resvalidate: any) => {
      if (!resvalidate.error) {
        if (resvalidate.length > 0) {
          this.existCliente = true;
          let login :any;
            login={
              'usuario':cedula,
              'clave':this.loginForm.clave,
            }
          

          await this.loginClient(url, this.configuracion, login, 'login');
          console.log('entra cuando meto cédula');
          
          await this.toaster.success('Ingrese su contraseña, Por defecto es su Cédula / Ruc', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true, titleClass: 'toast-css' });
          // await document.getElementById('inp-password').focus();
        } else {
          this.existCliente = false;
          this.toaster.warning('Usuario no encontrado, Registrelo.', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          // this.modalService.dismissAll ('Registrar');
          this.action='registrar';
        }
      } else {
        this.modalService.dismissAll({});
        this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
    // this.loadingAll = false;
  }

  async registerUser(form){
    console.log('form Register', form);
    this.registerClient(form);
    
  }
  async registerClient(form) {
    // this.modalCtrl.open(this.registerUserModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
    //   this.closeResult = `Closed with: ${result}`;
      let url_billing;
      let client = form;
      console.log(client);
      
      if (client.cedula && client.nombres && client.apellidos && client.email) {
        if (client.cedula.length <= 13) {
          this.toaster.info('Espere un momento ...', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          await this.webService.getUrlEmpresa().then(async (url) => {
            url_billing = url;
          });
          await this.webService.validateCi(url_billing, client.cedula).then(async (resValid: any) => {
            if (!resValid.error) {
              if (this.webService.validateEmail(client.email) == true) {
                client.nombres = this.webService.convertMayuscula(client.nombres);
                client.apellidos = this.webService.convertMayuscula(client.apellidos);
                client.razonsocial = client.nombres + ' ' + client.apellidos;
                if (resValid.valor == 0) {
                  client.es_pasaporte = 1;
                }
                (await this.webService.registerClientBilling(url_billing, client)).subscribe(async (resRegist: any) => {
                  if (!resValid.error) {
                    if (resRegist.rta) {
                      let data = {
                        nombres: client.nombres,
                        apellidos: client.apellidos,
                        cedula: client.cedula,
                        email: client.email
                      }
                      await this.webService.createBodyMailRegister(this.configuracion, data).then(async (resbody) => {
                        await this.webService.sendMailService(resbody).then((resmail: any) => {
                          // console.log(resmail);
                        });
                      });
                      let login = {
                        usuario: client.cedula,
                        clave: client.cedula
                      }
                      await this.loginClient(url_billing, this.configuracion, login, 'register');
                    } else {
                      this.toaster.warning('El cliente ya se encuentra regitrado, Inicie sesión', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                    }
                  } else {
                    this.toaster.error('Error al conectar con el servidos, vualva a intentarlo', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                  }
                });
              } else {
                this.toaster.error('Correo electrónico no válido', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                // this.dismissModal('Registrar');
                this.modalService.dismissAll();
              }
            } else {
              this.toaster.error('Error al conectar con el servidos, vualva a intentarlo', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          });
        } else {
          this.toaster.error('El número de idenficación no puede poseer más de 13 caracteres.', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          // this.dismissModal('Registrar');
          this.modalService.dismissAll();

        }
      } else {
        this.toaster.error('Campos vacios, ingrese su información', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        // this.dismissModal('Registrar');
        this.modalService.dismissAll();

      }

    }

    changeForm(action){
      console.log('action',action);
      
      this.action=action;
    }

}
