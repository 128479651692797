<!-- <app-submenu></app-submenu> -->
<!-- <app-menu></app-menu> -->

<ngx-loading [show]="loadingAll" [config]="{ backdropBorderRadius: '3px',  fullScreenBackdrop: true}" [template]="">
</ngx-loading>
<!-- <div class="bgHeader">

    <div style="padding-top: 10%;">
        <h1 class="fw-bold text-center" style="color: silver;">NUESTRA HISTORIA</h1>
    </div>


</div> -->

<section class="container">
    <br>
    <div class="divSelect" style="padding-top: 5%;">

        <!-- <img class="logo" src="https://drive.google.com/uc?export=view&id={{configuration}}" alt="" width="181px" height="120px"> -->
        <img class="logo" [src]="urlBase+configuration+'?alt=media'" alt="" width="181px" height="120px">

    </div>

    <div class="row" style="padding-top: 10%;">

        <div class="col-lg-6 pt-3 pl-4 ">
            <p class="" style="font-family: 'Helvetica'; font-size: xx-large;" id="str-history">
                <strong>
                    {{information?.nombre}}
                </strong>
            </p>
            <p class="pr-2 parrafoHistoria" style="padding-top: 5%; font-family: 'Helvetica';text-align: justify; ">
                {{information?.historia}}
            </p>

            <div class="col-lg-12 text-center mt-3">
                <span (click)="goToSocialNetwork(information?.facebook)"><i id="icoFace" class="bi bi-facebook"></i></span>
                <span class="ml-3" (click)="goToSocialNetwork(information?.youtube)"><i id="icoFace1" class="fab fa-youtube text-size-19 youtube youtube"></i></span>
                <span class="ml-3" (click)="goToSocialNetwork(information?.instagram)"><i  id="icoFace2" class="fab fa-instagram-square text-size-19 instagram"></i></span>
                <span class="ml-3" (click)="goToSocialNetwork(information?.twitter)"><i  id="icoFace3" class="fab fa-twitter text-size-19 twitter"></i></span>
                <span class="ml-3" (click)="goToSocialNetwork(information?.tiktok)"><i id="icoFace4" class="fab fa-tiktok text-size-19 tiktok"></i></span>
            
                <!-- <span (click)="goToSocialNetwork(information?.facebook)"><i class="fab fa-facebook-square text-size-19 facebook"></i></span>
                <span class="ml-3" (click)="goToSocialNetwork(information?.youtube)"><i class="fab fa-youtube text-size-19 youtube youtube"></i></span>
                <span class="ml-3" (click)="goToSocialNetwork(information?.instagram)"><i class="fab fa-instagram-square text-size-19 instagram"></i></span>
                <span class="ml-3" (click)="goToSocialNetwork(information?.twitter)"><i class="fab fa-twitter text-size-19 twitter"></i></span>
                <span class="ml-3" (click)="goToSocialNetwork(information?.tiktok)"><i class="fab fa-tiktok text-size-19 tiktok"></i></span> -->
            </div>
        </div>

        <div class="col-lg-6 p-0 " id="divImgHistoria" style="padding-top: 15%;">
            <img class="imgEj" width="100%" height="320px" style="object-fit: cover;" *ngIf="information?.imagenHistoria"[src]="urlBase+information?.imagenHistoria+'?alt=media'">
            <!-- <img class="imgEj" width="100%" height="320px" style="object-fit: cover;" *ngIf="information.imagenHistoria" src="https://drive.google.com/uc?export=view&id={{information.imagenHistoria}}"> -->
            <img class="imgEj" width="100%" height="auto" *ngIf="!information?.imagenHistoria" src="../../../assets/img/imgDefault/historia.jpg">
        </div>



    </div>

    <br>
    <div class="row pt-5">
        <div class="col-12">
            <h1 class="text-center" id="sobreNosotrosTitle" >¡CÓMO LO HACEMOS!</h1>
        </div>
        <div class="col-12">
            <h6  class="text-center" style="font-family: 'Helvetica'; font-size: large;" id="str-history1">
                <strong>
                    {{information?.nombre}}
                </strong></h6>
        </div>

        <div class="col-lg-6 col-md-6 pt-3" >
            <div class="w-100 text-center " >
                <img style="width: 25%; height: auto;" src="../../../assets/img/iconos-mision-01.png" alt="">
            </div>
            <br>
            <div class="w-100">
                <p class="divSelect pMV" id="pM">
                    QUÉ HACEMOS
                </p>
                <p class="parMV">
                    {{information?.mision}}
                </p>
            </div>
        </div>

        <div class="col-lg-6 col-md-6">
            <div class="w-100 text-center border border-light rounded">
                <img style="width: 25%; height: auto;" src="../../../assets/img/iconos-vision-01.png" alt="">
            </div>
            <br>
            <div class="w-100">
                <p class="pMV divSelect" id="pV">
                    PARA QUÉ LO HACEMOS
                </p>
                <p class="parMV">
                    {{information?.vision}}
                </p>
            </div>
        </div>

        <!-- <div class="col-lg-4">
            <div class="w-100 text-center border border-light rounded">
                <img class="rounded" width="75%" *ngIf="information.imagenValores" src="https://drive.google.com/uc?export=view&id={{information.imagenValores}}">
                <img class="rounded" width="75%" *ngIf="!information.imagenValores" src="https://drive.google.com/uc?export=view&id=1O6qHa3fef2bXutBz2TYuHTzyreCw7QcK">
            </div>
            <br>
            <div class="w-100">
                <p class="text-minuscula-texto-negro text-size-20 text-secondary">
                    Valores
                </p>
                <p class="text-minuscula-texto-negro text-justify text-size-14">
                    {{information.valores}}
                </p>
            </div>
        </div> -->
    </div>
</section>

<section class="container-fluid">
    <!-- <app-menu-responsivo></app-menu-responsivo> -->
</section>

<!-- <app-footer></app-footer> -->