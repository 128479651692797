import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentesRoutingModule } from './componentes-routing.module';
import { CardComponentComponent } from './card-component/card-component.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { WhatsAppComponent } from './whats-app/whats-app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoaderComponent } from './loader/loader.component';


@NgModule({
  declarations: [CardComponentComponent, WhatsAppComponent, HeaderComponent, LoginFormComponent, LoaderComponent],
  imports: [
    CommonModule,
    ComponentesRoutingModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule
    
  ],
  exports:[
    CardComponentComponent,
    WhatsAppComponent,
    HeaderComponent,
    LoaderComponent
    
  ]
})
export class ComponentesModule { }
