import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-collage',
  templateUrl: './collage.component.html',
  styleUrls: ['./collage.component.scss']
})
export class CollageComponent implements OnInit {

  @Input('banners') banners:any;
  urlFB= environment.firebaseUrl;
  constructor() { }

  ngOnInit(): void {
    console.log('Estos banners llegan', this.banners);
    
  }

}
