import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';

import { ServicesService } from '../services/services.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @ViewChild("loginUserModal", {}) loginUserModal: TemplateRef<any>;
  @ViewChild("registerUserModal", {}) registerUserModal: TemplateRef<any>;

  public closeResult: string;
  public information: any = {};
  public menu: any = {};

  public loadingAll = false;
  public loading = false;
  public viewFilter = false;

  constructor(
    private modalCtrl: NgbModal,
    private toaster: ToastrService,
    private webService: ServicesService,
    public router: Router,
    private localStorage: LocalStorage
  ) {
  }

  async ngOnInit() {
    await this.getOpcionMenu();

    await this.webService.getInformacion().then((resinfo) => {
      this.information = resinfo[0];
    });

    if (this.router.url.indexOf('/product') == 0) {
      this.orderByFilter('menor')
      this.viewFilter = true;
    } else {
      this.viewFilter = false;
    }
  }

  async goProductsCatalogue() {
    this.webService.goProductsCatalogue();
  }

  async goHome() {
    this.webService.goHomeClean();
    // this.webService.goHome();
  }

  async goBlog() {
    window.open(this.information.blog, "_blank");
  }

  async goAboutUs() {
    await this.webService.goAboutUs();
  }

  async orderByFilter(value) {
    this.webService.addObservableFilterProducts(value);
  }

  async getOpcionMenu() {
    await this.webService.getMenu().then(async (resmenu: any) => {
      await this.webService.stablishOptionsMenu(resmenu).then((resm: any) =>{
        this.menu = resm;
      });
    });
  }

}
