import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ServicesService } from '../services/services.service';

// let apiLoaded = false;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})

export class FooterComponent implements OnInit {
@Input('informacion') informacion:any
@Input('configuracion') configuracion:any
  // public videoYoutube;
  // public informacion: any;
  // public configuracion: any = {};
  public footerText;
  public videoYoutube;
 
  urlBase = environment.firebaseUrl;

  constructor(
    private webService: ServicesService,
  ) {

  }

  async ngOnInit() {
    // await this.getInformacion();
    // await this.getConfiguracion();
    console.log('config', this.configuracion);
    console.log('informacion', this.informacion);
    
    await this.createFooterText();
    let color  = this.configuracion.colorPrincipal;
    let colorLetra  = this.configuracion.colorLetra;
    document.documentElement.style.setProperty('--dynamic-color', color);
    document.documentElement.style.setProperty('--font-color-letter', colorLetra);
    // Cargar atributos para el video de youtube
    // const tag = document.createElement('script');
    // tag.src = 'https://www.youtube.com/iframe_api';
    // document.body.appendChild(tag);
    // apiLoaded = true;
  }

  // async getInformacion() {
  //   this.webService.getInformacion().then(async (dataInfo: any) => {
  //     this.informacion = dataInfo[0];
  //     console.log('information', this.informacion);
      
  //     if (dataInfo[0]?.video) {
  //       this.videoYoutube = this.webService.getIdVideoYoutube(dataInfo[0]?.video);
  //     } else {
  //       this.videoYoutube = '';
  //     }
  //   });
  // }

  // async getConfiguracion() {
  //   await this.webService.getConfiguracion().then(async (dataConf: any) => {
  //     // console.log("Conf footer", dataConf[0]);
  //     this.configuracion = dataConf[0];

  //     // console.log('datos', this.configuracion);
      

  //     // let tr = document.getElementById('tit-redes');
  //     // tr.style.color = dataConf[0].colorLetra;

  //     // let te = document.getElementById('tit-empresa');
  //     // te.style.color = dataConf[0].colorLetra;

  //     // let dr = document.getElementById('div-rights');
  //     // dr.style.color = dataConf[0].colorLetra;
  //     // dr.style.background = dataConf[0].colorPrincipal;

  //     // let sf = document.getElementById('div-footer');

  //     let icFB = document.getElementById('iFB');
  //     icFB.style.color = dataConf[0]?.colorPrincipal;
  //     let icIG = document.getElementById('iIG');
  //     icIG.style.color = dataConf[0]?.colorPrincipal;
  //     let iYT = document.getElementById('iYT');
  //     iYT.style.color = dataConf[0]?.colorPrincipal;
  //     let iTT = document.getElementById('iTT');
  //     iTT.style.color = dataConf[0]?.colorPrincipal;
  //     let iTW = document.getElementById('iTW');
  //     iTW.style.color = dataConf[0]?.colorPrincipal;
  //     let iSubs = document.getElementById('iSubs');
  //     iSubs.style.color = dataConf[0]?.colorPrincipal;
  //     let df = document.getElementById('divFooter');
  //     df.style.background = dataConf[0]?.colorPrincipal;
  //     df.style.color = dataConf[0]?.colorLetra;


  //     let imgFooter;
  //     if (dataConf[0]?.imgFooter) {
  //       imgFooter = dataConf[0]?.imgFooter;
  //     } else {
  //       imgFooter = '14zZja9c5cXlKXbuhpc23vavpZMi2H8EU';
  //     }
  //     // sf.style.background = 'url(https://drive.google.com/uc?export=view&id=' + imgFooter + ')';
  //     // sf.style.backgroundSize = 'cover';
  //   });
  // }

  async createFooterText() {
    await this.webService.getDate(0).then((resDate: any) => {
      // console.log("Fechita", resDate);
      // this.footerText = '© ' + resDate.año + ', Developed by Punto Pymes | Copyright | All rights reserved.';
      this.footerText = '© '+'Todos los derechos  reservados ' + resDate.año ;
    });
  }

  async goToSocialNetwork(redSocial) {
    // console.log(redSocial);
    window.open(redSocial, "_blank");
  }

  async goAboutUs() {
    await this.webService.goAboutUs();
    await this.webService.goUpPage();
  }

  async goPoliticsAndTerms() {
    await this.webService.goPolitics();
    await this.webService.goUpPage();
  }
  scrollToElement(): void {
    // console.log($element);
    let $element:any = 'secAboutUS'
    setTimeout(() => {
      $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      // this.listarTodos1();    
     }, 300);
  }

  async goHome() {
    this.webService.goHomeClean();
  }
  async goProductsCatalogue() {
    this.webService.goProductsCatalogue();
  }
}
