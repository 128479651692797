<div class="modal-body p-3">
    <div class="row">
        <div class="col-10">
            <span  *ngIf="action=='login'" class="spanCard" style="font-weight: 600;">Inicio de sesión</span>
            <span  *ngIf="action=='registrar'"  class="spanCard" style="font-weight: 600;">Registrarse</span>
        </div>
        <div class="col-2" type="button" (click)="closeModal()">
            <i class="bi bi-x-lg icoForm"></i>
        </div>
    </div>
 
    <ng-container *ngIf="action=='login'" >
        <div class="row py-4 px-2">
            <div class="col-12 mt-4 mb-4">
                <div class="btn-group btn-group-md btn-block rounded-pill" style="border: 1px silver solid;" role="group"
                    aria-label="...">
                    <button [ngClass]="{ 'btnSelect': flagSelectButton }" type="button" class="btn rounded-pill"
                        (click)="selectLoginDefault('user')">Cliente</button>
                    <button [ngClass]="{ 'btnSelect': !flagSelectButton }" type="button" class="btn rounded-pill"
                        (click)="selectLoginDefault('admin')">Administrador</button>
                </div>
            </div>
            <div class="col-12" *ngIf="flagSelectButton">
                <form  [formGroup]="formUser" >
                <div class="contenido3  mt-2 mb-2">
                    <label for="">Cédula o RUC</label>
                    <input  formControlName="usuario" type="text" placeholder="Ingrese su número de cédula o RUC"
                        class="form-control form-control-md ">
                </div>
                <div class="contenido3  mt-2 mb-2"  *ngIf="information.esPuntoVenta==0">
                    <label for="">Contraseña</label>
                    <input  formControlName="clave" type="password" placeholder="Ingrese su número de cédula o RUC"
                        class="form-control form-control-md ">
                </div>
                <div class="d-flex my-4" style="justify-content: center;">
                    <button [disabled]="formUser.invalid" 
                    (click)="loginSystemUser(formUser.value)"  class="btn  rounded-pill" style="background-color:#ea7e33; color: white;">
                        Verificar
                    </button>
                </div>
                <div class="d-flex my-4" style="justify-content: center;"  type="button" (click)="changeForm('registrar')">
                    <span style="font-size: 12px;">¿No tienes cuenta? <a class="" style="font-weight: bold;">Regístrate
                            aquí</a> </span>
                </div>
            </form>
            </div>
            <div class="col-12" *ngIf="!flagSelectButton">
                <form [formGroup]="formAdmin">
                    <div class="contenido3  mt-2 mb-2">
                        <label for="">Usuario</label>
                        <input formControlName="usuario" type="text" class="form-control form-control-md ">
                    </div>
                    <div class="contenido3 ">
                        <label for="">Contraseña</label>
                        <input formControlName="clave" type="password" class="form-control form-control-md ">
                    </div>
                    <div class="d-flex" style="justify-content: end;">
                        <span class=" text-right" style="font-size: 11px;">¿Olvidaste tu contraseña?</span>
                    </div>
                    <div class="d-flex my-4" style="justify-content: center;">
                        <button [disabled]="formAdmin.invalid" 
                                (click)="loginSystem(formAdmin.value)"
                                class="btn rounded-pill" style="background-color:#ea7e33; color: white;">
                                Iniciar Sesión
                        </button>
                    </div>
                    <div class="d-flex my-4" style="justify-content: center;"  type="button" (click)="changeForm('registrar')">
                        <span  style="font-size: 12px;">¿No tienes cuenta? <a class="" style="font-weight: bold;">Regístrate
                                aquí</a>
                        </span>
                    </div>
                </form>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="action=='registrar'" >
        <div class="row py-4 px-2">
            <div class="col-12" *ngIf="flagSelectButton">
                <form  [formGroup]="formRegister" >
                <div class="contenido3  mt-2 mb-2">
                    <label for="">Cédula o RUC</label>
                    <input  formControlName="cedula" type="text" placeholder="Ingrese su  cédula o RUC"
                        class="form-control form-control-md ">
                </div>
                <div class="contenido3  mt-2 mb-2">
                    <label for="">Nombres</label>
                    <input  formControlName="nombres" type="text" placeholder="Ingrese su nombre"
                        class="form-control form-control-md ">
                </div>
                <div class="contenido3  mt-2 mb-2">
                    <label for="">Apellidos</label>
                    <input  formControlName="apellidos" type="text" placeholder="Ingrese su apellido"
                        class="form-control form-control-md ">
                </div>
                <div class="contenido3  mt-2 mb-2">
                    <label for="">Correo Electrónico</label>
                    <input  formControlName="email" type="text" placeholder="Ingrese su correo"
                        class="form-control form-control-md ">
                </div>
                <div class="d-flex my-4" style="justify-content: center;">
                    <button [disabled]="formRegister.invalid" 
                    (click)="registerUser(formRegister.value)"  class="btn  rounded-pill" style="background-color:#ea7e33; color: white;">
                        Registrarse
                    </button>                 
                </div>
                <div class="d-flex " style="justify-content: center;"  >
                    <label style="font-size: 12px; font-weight: bold; " (click)="changeForm('login')" >Regresar</label>

                </div>
            </form>
            </div>

        </div>
    </ng-container>

    
</div>

