import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';


import { ServicesService } from '../../shared/services/services.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  public loadingAll = false;
  public information: any = {};
  configuration:any=[];
  urlBase = environment.firebaseUrl;
  constructor(
    private modalCtrl: NgbModal,
    private toaster: ToastrService,
    private webService: ServicesService,
  ) { }

  async ngOnInit() {
    this.loadingAll = true;
    await this.getAboutUs();
    await this.getConfiguration();
    this.loadingAll = false;
  }

  async getAboutUs() {
    await this.webService.getInformacion().then((resinfo) => {
      this.information = resinfo[0];
      // console.log('oiasasdsad', this.information);

      // console.log('imagen historia',this.information.imagenHistoria);
      
      
      if(this.information?.imagenHistoria){

      }
      // console.log(this.information);
    });
  }

  async getConfiguration(){
    await this.webService.getConfiguracion().then((resconfi) => {
      // console.log('config', resconfi);
      
      let strh = document.getElementById('str-history');
      strh.style.color = resconfi[0]?.colorPrincipal;
      let sbN = document.getElementById('sobreNosotrosTitle');
      sbN.style.color = resconfi[0]?.colorPrincipal;
      let sbN1 = document.getElementById('str-history1');
      sbN1.style.color = resconfi[0]?.colorPrincipal;
      let icoF = document.getElementById('icoFace');
      icoF.style.color = resconfi[0]?.colorPrincipal;
      let icoF1 = document.getElementById('icoFace1');
      icoF1.style.color = resconfi[0]?.colorPrincipal;
      let icoF2 = document.getElementById('icoFace2');
      icoF2.style.color = resconfi[0]?.colorPrincipal;
      let icoF3 = document.getElementById('icoFace3');
      icoF3.style.color = resconfi[0]?.colorPrincipal;
      let icoF4 = document.getElementById('icoFace4');
      icoF4.style.color = resconfi[0]?.colorPrincipal;
      let pM = document.getElementById('pM');
      pM.style.color = resconfi[0]?.colorPrincipal;
      let pV = document.getElementById('pV');
      pV.style.color = resconfi[0]?.colorPrincipal;
      this.configuration = resconfi[0]?.imgLogo;
      // console.log('', this.configuration);
      
    });
  }

  async goToSocialNetwork(redSocial){
    window.open(redSocial, "_blank");
  }
}
