import { Component, Input, OnInit } from '@angular/core';
import { Observable, Observer } from 'rxjs';


@Component({
  selector: 'app-card-component',
  templateUrl: './card-component.component.html',
  styleUrls: ['./card-component.component.scss']
})
export class CardComponentComponent implements OnInit {
  name = 'Mr';
  base64Image: any;

  ngOnInit() {}

  downloadImage() {
    let imageUrl ='https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.esquire.com%2Fes%2Fmoda-hombre%2Fg27748513%2Fcamisa-cuello-mao-hombre-verano%2F&psig=AOvVaw1pDWW6jIWS9_gXnLmxWot1&ust=1680216681283000&source=images&cd=vfe&ved=0CA8QjRxqFwoTCMDhiIWdgv4CFQAAAAAdAAAAABAE';
    // let imageUrl =
    //   'http://45.76.250.195:8090/'+`https://drive.google.com/uc?export=view&id=1-qC34MN6ND7d2jH48IBVaA3VZSNnGXma`;

    this.getBase64ImageFromURL(imageUrl).subscribe((base64data) => {
      console.log(base64data);
      this.base64Image = 'data:image/jpg;base64,' + base64data;
      // save image to disk
      var link = document.createElement('a');

      document.body.appendChild(link); // for Firefox

      link.setAttribute('href', this.base64Image);
      link.setAttribute('download', 'mrHankey.jpg');
      link.click();
    });
  }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL: string = canvas.toDataURL('image/png');

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }
}



