import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ServicesService } from 'src/app/shared/services/services.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
  constructor(
    private webService: ServicesService,
    private router: Router
   )
  {

   }

   canActivate(): Observable<boolean> {
    return this.webService.getRedirectRoute().pipe(
      map(route => {
        let ruta = route[0].ruta_inicio_defecto;
        console.log('route ====>', route);
        console.log('ruta ====>', ruta);

        if (route) {
          this.router.navigate([ruta]);
          return false;
        }
        return true;
      }),
      catchError(() => {
        // Manejar error si es necesario
        return [true];
      })
    );
  }
}
