import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/shared/services/services.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {
  urlBase = environment.firebaseUrl;
  idEmpresa= environment.idShop;
  // public configuracion: any;
  // public information: any;
  public clientLogin = {
    name: '',
    imagen: '',
    login: false,
    rol: ''
  }
  public cartProducts = {
    number: 0,
    total: 0.00
  }

  counterCar = 0;
  flagCarLocal = true;
  private storageSubscription!: Subscription;
  isNavbarCollapsed = true;

  @Output() redirectSection = new EventEmitter<any>();
  @Input('information') information: any;
  @Input('configuracion') configuracion: any;
  @Input('flagCar') flagCar: any;

  constructor
    (
      private webService: ServicesService,
      private modalService: NgbModal,
      private toaster: ToastrService,
      private router: Router,
    )
     {
     }

  async ngOnChanges(changes: SimpleChanges) {
    console.log('changes, ', changes);
    // this.configuracion = changes['configuracion']?.currentValue;
    // this.information = changes['information']?.currentValue;
    // console.log('llega input ==> ', this.configuracion);
    // console.log('llega input ==> information ', this.information);
    this.counterCar = parseInt(this.webService.getFromLocalStorage('carCount'));
    let carLocal = parseInt(this.webService.getFromLocalStorage('carLocal'));
    console.log('card LOCAL', carLocal);
    if (carLocal == 0) {
      this.flagCarLocal = false;
    }else{
      this.flagCarLocal = true;

    }
  }

  async ngOnInit() {
    // console.log('llega input ==> ', this.configuracion);
    // console.log('llega input ==> information ', this.information);
    const rgbaColor = this.webService.hexToRgba(this.configuracion.colorPrincipal, 0.2);
    document.documentElement.style.setProperty('--lighter-tone', rgbaColor);
    await this.isAutenticatedClient(this.configuracion).then(async (resauth: any) => {
      // Obtener productos del carrito y su total
      if (resauth.rta == true) {
        console.log('AQUIIIII _____________', resauth);

        this.storageSubscription = this.webService
          .getStorageObservable()
          .subscribe((data) => {
            this.counterCar = data.data;
            console.log('data', data);

          });
        this.counterCar = parseInt(this.webService.getFromLocalStorage('carCount'));
        await this.webService.getproductsCart({ id_cliente: resauth.data.PersonaComercio_cedulaRuc }).then(async (resprod: any) => {
          this.cartProducts = {
            number: resprod.data.length,
            total: await this.webService.calculateTotalCartProducts(resprod.data)
            // total: 0
          }
        });
        console.log('data', this.cartProducts);
        this.webService.saveToLocalStorage('carCount', this.cartProducts.number);
        this.counterCar = this.webService.getFromLocalStorage('carCount');

      } else {
        this.storageSubscription = this.webService
          .getStorageObservable()
          .subscribe((data) => {
            if (data.key == 'carLocal') {
              if (data.data == '1') {
                this.flagCarLocal = false;
              } else {
                this.flagCarLocal = true;

              }
            } else {
              this.counterCar = data.data;
            }
            console.log('data', data);

          });
      }
    });
    // await this.getConfiguracion();
    // await this.getInformation();
    await this.webService.observableLoginUser().subscribe((resLog: any) => {
      this.clientLogin = {
        name: resLog.name,
        imagen: resLog.imagen,
        login: resLog.login,
        rol: resLog.rol
      }
    });
    // Suscribirse a cambios del carrito
    await this.webService.observableProductsCart().subscribe((rescart: any) => {
      // console.log("suscrito car", rescart);
      this.cartProducts = {
        number: rescart.number,
        total: rescart.total
      }
    });

  }

  // async getConfiguracion() {
  //   await this.webService.getConfiguracion().then(async (data: any) => {
  //     this.configuracion = data[0];
  //     console.log(' ===> ', this.configuracion);
  //     let color  = this.configuracion.colorPrincipal;
  //     document.documentElement.style.setProperty('--dynamic-color', color);
  //     await this.isAutenticatedClient(this.configuracion).then(async (resauth: any) => {
  //       // Obtener productos del carrito y su total
  //       if (resauth.rta == true) {
  //         await this.webService.getproductsCart({ id_cliente: resauth.data.PersonaComercio_cedulaRuc }).then(async (resprod: any) => {
  //           this.cartProducts = {
  //             number: resprod.data.length,
  //             total: await this.webService.calculateTotalCartProducts(resprod.data)
  //           }
  //         });
  //       }
  //     });
  //   });
  // }
  // async getInformation() {
  //   await this.webService.getInformacion().then(async (data: any) => {
  //     if (!data.error) {
  //       if (data[0]) {
  //         this.information = data[0];
  //       } else {
  //         console.log("No se ha encontrado information");
  //       }
  //     } else {
  //       this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
  //     }
  //   });
  // }

  async isAutenticatedClient(configuracion) {
    let auth;
    await this.webService.isAuthenticatedClient(configuracion?.loginStorage).then((login: any) => {
      auth = login;
      if (login.rta == true) {
        this.clientLogin = {
          name: login.data.nameUser,
          imagen: login.data.imagen,
          login: true,
          rol: login.data.rol
        }
      } else {
        this.clientLogin = {
          name: '',
          imagen: '',
          login: false,
          rol: ''
        }
        this.counterCar = 0;
      }
    });
    return auth;
  }

  redirecTO(siteO: any) {
    let json = {

      siteO: siteO
    }
    localStorage.removeItem('groupName');
    localStorage.removeItem('idGroup');
    this.redirectSection.emit(json);
  }

  redirecTO2(url) {
    localStorage.removeItem('groupName');
    localStorage.removeItem('idGroup');
    this.router.navigateByUrl(url);
  }

  openModal(modal) {
    this.modalService.open(modal, { centered: true, size: 'sm' });
  }

  async signOff() {
    await this.webService.signOuth(this.configuracion.loginStorage).then((resClose: any) => { });

    this.webService.saveToLocalStorage('carCount', 0);
    this.counterCar = 0;
    this.cartProducts = {
      number: 0,
      total: 0.00
    }
    // await this.webService.goHome();
    // await this.webService.refreshPage(this.configuracion);
    await this.isAutenticatedClient(this.configuracion).then(async (resauth: any) => {
      // Obtener productos del carrito y su total
      if (resauth.rta == true) {
        await this.webService.getproductsCart({ id_cliente: resauth.data.PersonaComercio_cedulaRuc }).then(async (resprod: any) => {
          this.cartProducts = {
            number: resprod.data.length,
            total: await this.webService.calculateTotalCartProducts(resprod.data)
          }
        });
        console.log('total =======> ', this.cartProducts);

      }
      console.log('ESTA DESLOGUEADO');


      localStorage.setItem('isLoged', 'false');
      if(this.information.esPuntoVenta==1){
        this.router.navigateByUrl('catalogo')
        // this.flagCarLocal=true;
        this.webService.saveToLocalStorage('carLocal',0);
      }else{
        this.router.navigateByUrl('home/1')

      }
    });
  }

  async goClientProfile() {
    await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then(async (reslogin: any) => {
      if (reslogin.rta == true) {
        if (reslogin.data.rol == 'Client') {
          this.webService.goUserProfile();
          this.router.navigateByUrl('admin_usuario/perfil');
          // console.log("ar al cliente");
        }
        if (reslogin.data.rol == 'Administrator') {
          this.router.navigateByUrl('administrador/datos_generales');

          // this.webService.goAdminProfile();
          // console.log("ar al administrador");
        }
      }
    });
  }

  async goShoppingCartUser() {
    await this.isAutenticatedClient(this.configuracion).then(async (resauth: any) => {
      if (resauth.rta == true) {
        if (this.counterCar > 0) {
          await this.webService.goShoppingCart();
        } else {
          this.counterCar = 0;
          this.toaster.warning('Su carrito de compras esta vacio', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }

        // if (this.cartProducts.number > 0) {
        //   await this.webService.goShoppingCart();
        // } else {
        //   this.toaster.warning('Su carrito de compras esta vacio', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        // }
      } else {
        console.log('caso 2');
        this.toaster.warning('Su carrito de compras esta vacio', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });

        // await this.loginUser();
      }
    });
  }

  openNewModal(event: any) {
    console.log('llega event', event);

    if (event == 'registrar') {

    }
  }

  formSearchProduct = new FormGroup({
    text: new FormControl('', Validators.required)
  })

  async searchProduct(form: any) {
    let s = form.text
    // console.log('lo que llega', search);

    let type = 'search';
    this.router.navigateByUrl('product/' + type + '/' + s + '/' + 0);
  }

  seeExpand() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

}
