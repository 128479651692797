import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';


import { ServicesService } from '../../shared/services/services.service';
import { environment } from 'src/environments/environment';
import { MigrationService } from 'src/app/shared/services/migration.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

let execute;
declare function abrirModalCodigo(modal:string):any;
declare function cerrarModal1(params:string):any;

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.scss']
})
export class CarritoComponent implements OnInit {


  @ViewChild("ModalDatailValues", {}) ModalDatailValues: TemplateRef<any>;
  @ViewChild("ModalPayPhone", {}) ModalPayPhone: TemplateRef<any>;
  @ViewChild("ModalTutorialPayPhone", {}) ModalTutorialPayPhone: TemplateRef<any>;
  @ViewChild("ModalWaitTransactionPayphone", {}) ModalWaitTransactionPayphone: TemplateRef<any>;
  @ViewChild("ModalTransactionResult", {}) ModalTransactionResult: TemplateRef<any>;
  @ViewChild("ModalDatafast", {}) ModalDatafast: TemplateRef<any>;
  @ViewChild("paypalModal", {}) paypalModal: TemplateRef<any>;


  @ViewChild("informationClientModal", {}) informationClientModal: TemplateRef<any>;
  @ViewChild("showPaymentButtonsModal", {}) showPaymentButtonsModal: TemplateRef<any>;
  @ViewChild("ModalDatailValuesShop", {}) ModalDatailValuesShop: TemplateRef<any>;


  public loadingAll = false;
  public closeResult: string;
  public configuracion: any = {};
  public user: any = {};
  public userUpdate: any = {};
  public provinces: any = {};
  public cartBD = [];
  public shoppingCart = [];
  public messageModal = {
    title: '',
    description: ''
  };
  public totalCart;
  public buttonVisibility: any = {}
  public valuesOrder: any = {};
  public bankAccountData = {
    rta: false,
    view: false,
    data: {
      numero: '',
      nombre: '',
      banco: '',
      ci_ruc: '',
      tipo_cuenta: ''
    }
  };
  public qrAccountData = {
    rta: false,
    view: false,
    data: {
      qr1: '',
      qr2: '',
      qr3: '',

    }
  };

  public payPhoneAccount = {
    valid: false,
    iso2: '',
    dialCode: '',
    number: '',
    numberComplet: ''
  }
  public dataFast = {
    rta: false,
    id: '',
    dominio: '',
    id_cotizacion: '',
    path: ''
  };

  public transaction = {
    status: '',
    result: '',
    reference: 0
  }
  public notificationAdmin = {
    body: {
      title: "",
      description: "Nuevo pedido registrado",
      message: "Nuevo pedido registrado"
    },
    url: "",
    entity: "firebase"
  }
  // Boton de paypal
  public payPalConfig?: IPayPalConfig;

  public addressDeliveryData = {
    province: [],
    canton: [],
    client: {} as any,
    infoSend: {
      nombres: '',
      apellidos: '',
      provincia: 0,
      canton: 0,
      direccion: '',
      referencia_domicilio: '',
      celular: ''
    },
    btnShop: false,
    myLocation: true,
    emptyAttributes: {} as any
  }

  empresa = environment.empresa;
  urlBase = environment.firebaseUrl;

  // =========== COMPROBANTE VENTA =========
  comprobante: any;
  flagComprobante=false;
  information:any=[];
  tipoCliente:any;

  // GENERAR COMANDA 
  id_mesa:any;
  id_mesero:any;
  flagMakeComanda = false;
  tiposPago:any=[];
  url:any;
  banderaAC:any;
  idComanda:any;
  contactoFinal:any;
  prods:any=[];
  // --------------------------------- MODALES -----------------------------------------
  @ViewChild("modalCarritoLocal") modalCarritoLocal: TemplateRef<any> | undefined;
  @ViewChild("modalPrintComanda") modalPrintComanda: TemplateRef<any> | undefined;

  constructor(
    private webService: ServicesService,
    public router: Router,
    private modalCtrl: NgbModal,
    private toaster: ToastrService,
    private elementRef: ElementRef,
    private fireService: MigrationService
  ) {
  }

  async ngOnInit() {
    this.loadingAll = true;
    this.tipoCliente = this.webService.getFromLocalStorage('tipoCliente');
    console.log('tipo Cliente  ---> ', this.tipoCliente);
    
    let pathDatafast = this.router.url.indexOf('resourcePath');
    if (pathDatafast > 0) {
      let id_cotization = this.router.url.indexOf('carrito?id_cotizacion=');
      let all = this.router.url.substring(id_cotization, (this.router.url.length));
      // id_cotizacion
      if (id_cotization > 0) {
        let start = all.indexOf('=');
        let end = all.indexOf('&');
        this.dataFast.id_cotizacion = all.substring(start + 1, (end));
      }
      // Resource Path
      let r = all.replace(/%2F/g, '/');
      let v1 = r.indexOf("v1");
      this.dataFast.path = r.substring(v1, (r.length));
      this.dataFast.rta = true;
    }
    await this.getConfiguracion();
    await this.getInformation();

    // Suscribirse a cambios del carrito
    // await this.webService.observableProductsCart().subscribe((rescart: any) => {
    //   this.totalCart = rescart.total;
    // });
    if (this.dataFast.rta == true) {
      await this.checkTransactionDatafast(this.dataFast);
    }
    await this.webService.goUpPage();
    this.loadingAll = false;
  }

  isEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  async getConfiguracion() {
    await this.webService.getConfiguracion().then(async (data: any) => {
      console.log("conf", data[0]);
      if (!data.error) {
        this.configuracion = data[0];
        await this.getShoppingCart(this.configuracion);
        await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then((login: any) => {
          if (login.rta == true) {
            this.user = login.data;
            this.addressDeliveryData.client = this.user;
          } else {
            //console.log("no se ha encontrado login");
          }
        });

      let color  = this.configuracion.colorPrincipal;
      let colorLetra  = this.configuracion.colorLetra;
      let colorLetraSecundario  = this.configuracion.colorLetraSecundario;
      console.log('color', color); 
      document.documentElement.style.setProperty('--dynamic-color', color);
      document.documentElement.style.setProperty('--color-letter-primary', colorLetra);
      document.documentElement.style.setProperty('--color-letter-secondary', colorLetraSecundario);

        // Boton Continuar comprando
        // let bc = document.getElementById('btn-cart');
        // bc.style.border = '1px Solid ' + this.configuracion.colorPrincipal;
        // bc.style.color = this.configuracion.colorPrincipal;

        // let btp = document.getElementById('btn-topay');
        // btp.style.background = this.configuracion.colorPrincipal;
        // btp.style.color = this.configuracion.colorLetra;

        // let btp2 = document.getElementById('btn-topay2');
        // btp2.style.background = this.configuracion.colorPrincipal;
        // btp2.style.color = this.configuracion.colorLetra;

        // let btph = document.getElementById('btn-topay-home');
        // btph.style.background = this.configuracion.colorPrincipal;
        // btph.style.color = this.configuracion.colorLetra;


        // let tht = document.getElementById('th-total');
        // tht.style.color = this.configuracion.colorPrincipal;

        // Mostrar todo el catalogo de productos
        // document.getElementById("div-catalogo").style.height = "100%";

      } else {
        this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  async getInformation() {
    await this.webService.getInformacion().then((resinfo) => {
      this.information=resinfo[0];
      console.log('inf buss', this.information);
      
      this.notificationAdmin.body.title = resinfo[0]?.nombre;
    });
  }

  async getShoppingCart(configuracion) {
    await this.webService.isAuthenticatedClient(configuracion.loginStorage).then(async (login: any) => {
      // let rescart = this.webService.getFromLocalStorage('carShop');
      await this.webService.getproductsCart({ id_cliente: login.data.PersonaComercio_cedulaRuc }).then(async (rescart: any) => {
        console.log('RESSSSS ', rescart);
        
        if (rescart.rta == true) {
          console.log('ENTRA AQUI 1');
          
          this.cartBD = rescart.data;
          console.log('this.configuracion.tipo_web', this.configuracion.tipo_web);
          
          if (this.configuracion.tipo_web == 1) {
            this.shoppingCart = rescart.products;
            console.log(' ========>',this.shoppingCart);
            
          }
          if (this.configuracion.tipo_web == 2) {
            await this.webService.createTallasProduct(rescart.products).then(async (resTalla) => {
              this.shoppingCart = resTalla;
              // this.shoppingCart = this.eliminarDuplicadosYAgregarCantidad(this.shoppingCart);
            });
          }
          await this.webService.calculateTotalCartProducts(rescart.data).then((restot) => {
            this.totalCart = restot;
          });
        } else {
          this.toaster.info('Se ha agotado el stock, de los productos del carrito', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          await this.webService.goHome();
        }
      });
    });
  }

  eliminarDuplicadosYAgregarCantidad(productos: any[]): any[] {
    const productosMap: { [key: string]: any } = {};
  
    productos.forEach(producto => {
      if (productosMap[producto.id_producto]) {
        productosMap[producto.id_producto].quantity += 1;
      } else {
        productosMap[producto.id_producto] = { ...producto, quantity: 1 };
      }
    });
  
    return Object.values(productosMap);
  }
  async modalDeleteFile(deleteFileModal, data, type) {
    if (type == "Product") {
      this.messageModal = {
        title: 'Seguro que desea eliminar el producto',
        description: data.pro_nom + ' ' + data.talla + ' ' + data.color
      }
    }
    if (type == 'EmptyCart') {
      this.messageModal = {
        title: 'Seguro que desea vaciar su carrito',
        description: ''
      }
    }
    this.modalCtrl.open(deleteFileModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      this.loadingAll = true;
      if (type == "Product") {
        await this.webService.deleteProductCart(data.id_carrito).then(async (resdel: any) => {
          if (resdel.rta == true) {
            await this.webService.updateObservableShoppingCart(this.user).then((res) => { });
            let counter = parseFloat(localStorage.getItem('carCount')); 


            counter -= 1;
            // localStorage.setItem('carCount', JSON.stringify(counter));
            this.webService.saveToLocalStorage('carCount', counter);
            this.toaster.success('Producto eliminado del carrito', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            this.shoppingCart = await this.webService.deleteItemDuplicated(this.shoppingCart, data);
            if (this.shoppingCart.length == 0) {
              // await this.webService.goHome();
              this.router.navigateByUrl('catalogo');
            }
          } else {
            this.toaster.error('Algo sucedió, intente nuevamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        });
      }
      if (type == 'EmptyCart') {
        await this.emptyShoppingCart(this.user);
      }
      this.loadingAll = false;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async quantityProduct(tipo, product) {
    await this.webService.settingQuantityProduct(tipo, product).then(async (resQuant) => {
      console.log('ENTRA METODO', resQuant);
      
      if (resQuant.rta == true) {
        this.loadingAll = true;
        await this.webService.createDataInsertProductCart(product, this.user, '').then(async (resinsert: any) => {
          console.log('AGREGAR CARRITO 1 ', resinsert);
          console.log('datos que van', product.id_carrito);
          console.log('PROD', product);
          const { id, ...newObject } = resinsert;

          // await this.webService.updateProductsCart(product.id_carrito, resinsert).then(async (resupd: any) => {
          await this.webService.putGeneral2("https://www.pulpoplace.com:8448/carrito/update/" + product.id_carrito, newObject).subscribe(async (resupd: any) => {
            console.log('AGREGAR CARRITO 2 ', resupd);
            
            if (resupd.rta == true) {
              product = resQuant.data;
              let counter = parseFloat(localStorage.getItem('carCount')) ;
              if(tipo=='quit'){
                
                counter -= 1;
                console.log('QUITA');
                
                // localStorage.setItem('carCount', JSON.stringify(counter));
                this.webService.saveToLocalStorage('carCount', counter);
              }else{
                console.log('AGREGA');

                counter += 1;
                // localStorage.setItem('carCount', JSON.stringify(counter));
                this.webService.saveToLocalStorage('carCount', counter);

              }

              console.log('carrito', resupd);
              console.log('carrito true', this.shoppingCart);
                
                
              await this.webService.calculateTotalCartProducts2(this.shoppingCart).then((restot) => {
                console.log('total', restot);
                this.totalCart = restot;
              });
     
              await this.webService.updateObservableShoppingCart(this.user).then((res) => { });
            } else {
              this.toaster.error('Algo sucedió, intente nuevamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          });
        });
        this.loadingAll = false;
      } else {
        this.toaster.warning(resQuant.message, '', { timeOut: 3000, positionClass: 'toast-bottom-center', closeButton: true, progressBar: true });
      }
    });
  }

  async goProductsCatalogue() {
    this.webService.goProductsCatalogue();
  }

  // Vaciar carrito de compras
  async emptyShoppingCart(user) {
    await this.webService.emptyProductCart({ id_cliente: user.PersonaComercio_cedulaRuc }).then(async (resempty: any) => {
      if (resempty.rta == true) {
        this.shoppingCart = [];
        await this.webService.updateObservableShoppingCart(this.user).then((res) => { });
        // await this.webService.goHome();
        this.router.navigateByUrl('catalogo')
        localStorage.setItem('carCount','0');
        this.toaster.success('Su carrito ha sido vaciado', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      } else {
        this.toaster.error('Algo sucedió, intente nuevamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  // ******** Nuevo metodo de compra ********

  async addProvinceClient(id_province) {
    if (this.addressDeliveryData.emptyAttributes.provincia == true) {
      await this.initizalizeInfoSend('empty', id_province, 0).then((res: any) => { });
    }
    await this.getCantonesProvince(id_province);
  }

  async getCantonesProvince(id_province) {
    let query = {
      'tabla': 'bill_canton',
      'atributo': 'bill_provincia_idProvincia',
      'valor_atributo': id_province,
      'filas': '*'
    };
    this.loadingAll = true;
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.getCantonesProvince(url, query).then(async (rescantones: any) => {
        if (rescantones.rta == true) {
          await this.webService.stablishProvinceAndCantonClient(this.addressDeliveryData.client, rescantones.data, 'canton').then(async (res: any) => {
            if (res.id == 0) {
              this.addressDeliveryData.infoSend.canton = res.data[0].idCanton;
            }
            this.addressDeliveryData.canton = res.data;
          });
        } else {
          this.addressDeliveryData.canton = [];
        }
      });
    });
    this.loadingAll = false;
  }

  // Formulario Datos del cliente
  async modalInformationClient(informationClientModal) {
    if (this.user.rol == 'Client') {
      let url = '';
      let open_modal = true;
      let client_aux;;
      // Inicializar informacion
      await this.initizalizeInfoSend('all', 0, 0).then((res: any) => { });
      this.loadingAll = true;
      await this.webService.getUrlEmpresa().then(async (url_empresa) => {
        this.url = this.modificarURL(url_empresa);
        url = url_empresa;
      });
      // Obtener datos del cliente, para actualizarlo
      await this.webService.getDataUserByCedula(url, this.user.PersonaComercio_cedulaRuc).then(async (resuser: any) => {
        if (resuser.rta == true) {
          // resuser.data.rol = this.user.rol;
          client_aux = resuser.data;
          this.addressDeliveryData.client = resuser.data;
          await this.webService.getProvincesEcuador(url).then(async (resprovinces: any) => {
            if (resprovinces.rta == true) {
              await this.webService.stablishProvinceAndCantonClient(client_aux, resprovinces.data, 'province').then(async (res: any) => {
                this.addressDeliveryData.province = res.data;
                await this.getCantonesProvince(res.id);
              });
            }
          });
          await this.webService.validateInformationClient(resuser.data).then(async (res: any) => {
            this.addressDeliveryData.emptyAttributes = res.empty;
            if (res.rta == true) {
              this.addressDeliveryData.btnShop = true;
            } else {
              this.addressDeliveryData.btnShop = false;
            }
          });
          this.addressDeliveryData.infoSend = {
            nombres: resuser.data.nombres,
            apellidos: resuser.data.apellidos,
            provincia: resuser.data.provincia,
            canton: resuser.data.canton,
            direccion: resuser.data.direccion,
            referencia_domicilio: resuser.data.referencia_domicilio,
            celular: resuser.data.celular
          }
        } else {
          this.toaster.error('Ha ocurrido un error al obtener sus datos', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          open_modal = false;
        }
      });
      this.loadingAll = false;
      // Abrir modal si no hay errores
      if (open_modal == true) {
        this.modalCtrl.open(informationClientModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
          this.closeResult = `Closed with: ${result}`;
          if (result == 'update') {
            this.loadingAll = true;
            client_aux.nombres = this.webService.convertMayuscula(this.addressDeliveryData.infoSend.nombres);
            client_aux.apellidos = this.webService.convertMayuscula(this.addressDeliveryData.infoSend.apellidos);
            client_aux.provincia = this.addressDeliveryData.infoSend.provincia;
            client_aux.canton = this.addressDeliveryData.infoSend.canton;
            client_aux.direccion = this.addressDeliveryData.infoSend.direccion;
            client_aux.referencia_domicilio = this.addressDeliveryData.infoSend.referencia_domicilio;
            client_aux.celular = this.addressDeliveryData.infoSend.celular;
            let send = {
              data: client_aux,
              endPoint: url + "update_all_client"
            }
            // await this.webService.updateUserData(send).then(async (resupd: any) => {
            await this.webService.postGeneral2(`${this.url}common/movil/update_all_client`,client_aux).subscribe(async (resupd: any) => {
              this.loadingAll = false;
              if (resupd.rta == true) {
                client_aux.rol = this.user.rol;
                await this.webService.saveUserLocalStorage(client_aux, this.configuracion.loginStorage, this.user.rol);
                this.modalInformationClient(informationClientModal);
              } else {
                this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              }
            });
          }
          if (result == 'shop') {
            await this.webService.validateInformationClient(this.addressDeliveryData.infoSend).then(async (resavald: any) => {
              if (resavald.rta == true) {
                this.modalShowPaymentButtons(this.showPaymentButtonsModal);
              } else {
                this.toaster.warning('Por favor, Complete la información del formulario, para proceder con la compra.', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              }
            });
          }
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          //console.log(reason);
          //console.log("cancelar");
        });
      }
    } else {
      this.toaster.warning('Por favor, Iniciar sesion como cliente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  // Tipo de direccion a ingresar
  async selectAddressDelivery() {
    this.addressDeliveryData.myLocation = !this.addressDeliveryData.myLocation;
    if (this.addressDeliveryData.myLocation == true) {
      await this.initizalizeInfoSend('mine', 0, 0).then((res: any) => { });
    } else {
      await this.initizalizeInfoSend('empty', this.addressDeliveryData.client.provincia, 0).then((res: any) => { });
    }
  }

  // Obtener botones de pago y datos de cuenta bancaria para Transferencia
  async getButtonsPay() {
    let resp;
    let bankAccountData;
    let qrAccountData;
    this.loadingAll = true;
    await this.webService.getUrlEmpresa().then(async (url) => {      
      await this.webService.visibilityPurchaseButtons(this.configuracion, this.cartBD).then(async (resbuttons) => {
        //console.log('resbuttons', resbuttons);
        
        if (resbuttons.tranferencia == true) {
          await this.webService.getInformacion().then(async (resinfo) => {
              //console.log('res', resinfo);
              
            bankAccountData = {
              rta: true,
              view: false,
              data: {
                numero: resinfo[0].numeroCuenta,
                nombre: resinfo[0].nombreCuenta,
                banco: resinfo[0].nombreBanco,
                ci_ruc: resinfo[0].ruc_ci_cuenta,
                tipo_cuenta: resinfo[0].tipo_cuenta
              }
            }
            resp = {
              bankAccountData: bankAccountData,
              qrAccountData: qrAccountData,
              buttonVisibility: resbuttons
            }
          });
        } else {
          // //console.log("ESTO REGRESA");
          bankAccountData = {
            rta: false,
            view: true,
            data: {
              // numero: resinfo[0].numeroCuenta,
              // nombre: resinfo[0].nombreCuenta,
              // banco: resinfo[0].nombreBanco
            }
          }
          resp = {
            bankAccountData: bankAccountData,
            qrAccountData: qrAccountData,
            buttonVisibility: resbuttons
          }
          
        }

        if (resbuttons.pagoQR == true){
          await this.webService.getInformacion().then(async (resinfo) => {
            //console.log('res', resinfo);
            
            qrAccountData = {
            rta: true,
            view: false,
            data: {
              qr1: resinfo[0].imgQrP,
              qr2: resinfo[0].imgQrS,
              qr3: resinfo[0].imgQrT,
            }
          }
          resp = {
            bankAccountData: bankAccountData,
            qrAccountData: qrAccountData,
            buttonVisibility: resbuttons
          }
        });
        }else{
          qrAccountData = {
            rta: false,
            view: true,
            data: {
              // numero: resinfo[0].numeroCuenta,
              // nombre: resinfo[0].nombreCuenta,
              // banco: resinfo[0].nombreBanco
            }
          }
          resp = {
            bankAccountData: bankAccountData,
            qrAccountData: qrAccountData,
            buttonVisibility: resbuttons
          }
          
        }
      });
    });
    this.loadingAll = false;
    return resp;
  }

  // Mostrar listado de botones de pago en el modal
  async modalShowPaymentButtons(showPaymentButtonsModal) {
    await this.getButtonsPay().then((resbn: any) => {
      //console.log('resbn',resbn);
      
      this.bankAccountData = resbn.bankAccountData;
      this.buttonVisibility = resbn.buttonVisibility;
      this.qrAccountData = resbn.qrAccountData
    });
    this.modalCtrl.open(showPaymentButtonsModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      await this.calculatedValuesCrateOrder(result, this.addressDeliveryData);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      //console.log(reason);
    });
    // let hm = document.getElementById('div-header-pay');
    // hm.style.background = this.configuracion.colorPrincipal;
    // hm.style.color = this.configuracion.colorLetra;
  }

  // Calcular valores a pagar y generar data de la orden
  async calculatedValuesCrateOrder(type, deliveryData) {
    //console.log('ENTRA AC[A',deliveryData, type);
    
    await this.webService.determinateArancelsAndDiscounts(type, this.configuracion, deliveryData).then(async (resarancel: any) => {
      await this.webService.calculateSeparateSubtotal(resarancel, this.shoppingCart).then(async (ressubtot) => {
        resarancel.calculos = ressubtot;
        deliveryData.client.EnvioDomicilio = deliveryData.infoSend;
        deliveryData.client.bankAccountData = this.bankAccountData;
        deliveryData.client.qrCode = this.qrAccountData;
        // Aqui llamar funcion de crear cotizacion
        await this.webService.generateDataCotization(this.shoppingCart, deliveryData.client, resarancel, this.configuracion, type).then(async (rescotization: any) => {
          // Fin de llamar funcion de crear cotizacion
          if (resarancel.costoEnvio.rta == true) {
            await this.webService.calculateShippingCostTotal(resarancel).then((restotal: any) => {
              resarancel.subtotalAux = restotal.total;
            });
          } else {
            resarancel.subtotalAux = 0;
          }
          // if(this.information.esPuntoVenta==0){

          // }else{

          // }
          await this.detailValuesShopModal(this.ModalDatailValuesShop, type, deliveryData, resarancel, rescotization, this.configuracion, this.shoppingCart);

        });
      });
    });
  }

  // Modal para mostrar el detalle de los valores de la compra
  async detailValuesShopModal(ModalDatailValues, type, deliveryData, values, cotization, configuracion, shoppingCart) {
    // Agregar datos de envio de compra a domicilio
    let client = deliveryData.client;

    this.valuesOrder = values;
    if (type == 4) {
      this.bankAccountData.view = true;
    } else {
      this.bankAccountData.view = false;
    }
    if (type == 9) {
      this.qrAccountData.view = true;
    } else {
      this.qrAccountData.view = false;
    }
    this.modalCtrl.open(ModalDatailValues, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      // //console.log('this.closeResult',this.closeResult);
      
      this.loadingAll = true;
      await this.webService.getUrlEmpresa().then(async (url) => {
        switch (type) {
          case 1:
            //console.log("Recoger en la tienda");
            await this.generalPaymentButton(type, client, values, cotization, configuracion, shoppingCart, url);
            break;
          case 2:
            //console.log("Entrega a domicilio");
            await this.generalPaymentButton(type, client, values, cotization, configuracion, shoppingCart, url);
            break;
          case 3:
            //console.log("Enviar al whatsapp");
            await this.whatsappPaymentButton(type, client, values, cotization, configuracion, shoppingCart, url);
            break;
          case 4:
            //console.log("Transferencia bancaria");            
            await this.generalPaymentButton(type, client, values, cotization, configuracion, shoppingCart, url);
            break;
          case 5:
            if (this.user.rol == 'Client') {
              await this.validateAccountPayphoneModal(type, client, values, cotization, configuracion, shoppingCart, url);
            } else {
              this.toaster.warning('Por favor, Iniciar sesion como cliente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
            break;
          case 6:
            if (this.user.rol == 'Client') {
              // //console.log("DataFast", client.rol);
              await this.webService.saveDeliveryDataLocalStorage(deliveryData, this.configuracion.localStorage).then(async (resadd) => { });
              await this.datafastPaymentButton(type, client, values, cotization, configuracion, shoppingCart, url);
            } else {
              this.toaster.warning('Por favor, Iniciar sesion como cliente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
            break;
          case 7:
            //console.log("PlacetoPay");
            break;
          case 8:
            if (this.user.rol == 'Client') {
              // //console.log("PayPay", client.rol);
              await this.payPalPaymentButton(type, client, values, cotization, configuracion, shoppingCart, url);
              this.modalCtrl.open(this.paypalModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm', backdrop: 'static', keyboard: false }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
                // //console.log("Close", result);
              }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                // //console.log("Cancelar", reason);
              });
            } else {
              this.toaster.warning('Por favor, Iniciar sesion como cliente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
            break;
            case 9:
              //console.log("PAGO CON QR");            
              await this.generalPaymentButtonQR(type, client, values, cotization, configuracion, shoppingCart, url);
              break;
        }
        

      });
      this.loadingAll = false;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      //console.log(reason);
      // //console.log(this.closeResult);
      
    });
    let hv = document.getElementById('div-header-values');
    hv.style.background = this.configuracion.colorPrincipal;
    hv.style.color = this.configuracion.colorLetra;
  }

  // Inicializar datos de compra del cliente
  // all = Vaciar todo
  // empty = Vaciar algunos campos
  // mine = Cargar la direccion del cliente
  async initizalizeInfoSend(type, id_province, id_canton) {
    if (type == 'all') {
      this.addressDeliveryData = {
        province: [],
        canton: [],
        client: {} as any,
        infoSend: {
          nombres: '',
          apellidos: '',
          provincia: id_province,
          canton: id_canton,
          direccion: '',
          referencia_domicilio: '',
          celular: ''
        },
        btnShop: false,
        myLocation: true,
        emptyAttributes: {} as any
      }
    }
    if (type == 'empty') {
      this.addressDeliveryData.infoSend.direccion = '';
      this.addressDeliveryData.infoSend.referencia_domicilio = '';
      this.addressDeliveryData.infoSend.provincia = id_province;
      this.addressDeliveryData.infoSend.canton = id_canton;
    }
    if (type == 'mine') {
      this.addressDeliveryData.infoSend = {
        nombres: this.addressDeliveryData.client.nombres,
        apellidos: this.addressDeliveryData.client.apellidos,
        provincia: this.addressDeliveryData.client.provincia,
        canton: this.addressDeliveryData.client.canton,
        direccion: this.addressDeliveryData.client.direccion,
        referencia_domicilio: this.addressDeliveryData.client.referencia_domicilio,
        celular: this.addressDeliveryData.client.celular
      }
      await this.getCantonesProvince(this.addressDeliveryData.client.provincia);
    }
  }



  generateRandomString() {
    let letters = 'abcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < 5; i++) {
      let randomIndex = Math.floor(Math.random() * letters.length);
      result += letters[randomIndex];
    }
    return result;

  }
  // ******* Fin Nuevo metodo de compra ********

  // General
  async generalPaymentButton(type, client, values, cotization, configuracion, shoppingCart, url) {
    //console.log('type',type);

    // if(!this.flagComprobante){
    //   this.toaster.warning('Es obligatorio subir el comprobante', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });

    // }else{
      //console.log('ENTRA UNA VEZ');
      this.loadingAll=true;
      
      if(type==4 ){

        if(!this.flagComprobante){
        }else{
          let randomC = this.fireService.generateRandomString();
          //Ruta Base de Datos
          let bdC: any;
          let rutaC = `${this.empresa}/comprobantes/${randomC}_${this.comprobante.name  
          }`; 
          let rutaCBD = `comprobantes%2F${randomC}_${this.comprobante.name}`
  
          await this.fireService
          .uploadImage(this.comprobante, rutaC)
          .then((data: any) => {
            // bdC = this.fireService.cambiarUrlFireBtoDataB(data);
          });
          cotization.cotizacion.tipo_pago_app = await rutaCBD;
        }
        //console.log('ENTRA CUANDO ES TRANSFERENCIA');

        // await this.webService.insertCotization(url, cotization).then(async (rescotization: any) => {
        await this.webService.postGeneral2(url+"insert_cotizacion", cotization).subscribe(async (rescotization: any) => {

          console.log("insertar", rescotization);
          if (rescotization.rta == true) {
            this.loadingAll=false;

            await this.webService.createMailBody(type, client, values, configuracion, shoppingCart, rescotization.id_cotizacion).then(async (resbody) => {
              await this.webService.sendMailService(resbody).then(async (resmail: any) => {
                // //console.log("Mail", resmail);
                // await this.webService.sendNotificationAdministrator(url, this.notificationAdmin).then((resnotify) => { });
                await this.webService.postGeneral2(url + "send_notification_all_client", this.notificationAdmin).subscribe((resnotify) => { });
                let data = {
                  status: 'Accepted',
                  result: 'La transacción se ha realizado con éxito, en breve un asesor se comunicará para coordinar el despacho.',
                  reference: rescotization.id_cotizacion
                }
                await this.TransactionResultModal(data);
              });
            });
          } else {
            this.loadingAll=false;

            if (rescotization.code == 0) {
              this.toaster.warning(rescotization.message, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            } else {
              this.toaster.info('Algo ha sucedido, intente nuevamente 1', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          }
        });
        
        
      } 
      else{

        //console.log('ENTRA AL CASO CONTRARIO');
        // await this.webService.postGeneral2(url+"insert_cotizacion", cotization).subscribe(async (rescotization: any) => {

        //   console.log("insertar", rescotization);
        //   if (rescotization.rta == true) {
        //     await this.webService.createMailBody(type, client, values, configuracion, shoppingCart, rescotization.id_cotizacion).then(async (resbody) => {
        //       await this.webService.sendMailService(resbody).then(async (resmail: any) => {
        //         // //console.log("Mail", resmail);
        //         // await this.webService.sendNotificationAdministrator(url, this.notificationAdmin).then((resnotify) => { });
        //         await this.webService.postGeneral2(url + "send_notification_all_client", this.notificationAdmin).subscribe((resnotify) => { });
        //         let data = {
        //           status: 'Accepted',
        //           result: 'La transacción se ha realizado con éxito, en breve un asesor se comunicará para coordinar el despacho.',
        //           reference: rescotization.id_cotizacion
        //         }
        //         await this.TransactionResultModal(data);
        //       });
        //     });
        //   } else {
        //     if (rescotization.code == 0) {
        //       this.toaster.warning(rescotization.res.message, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        //     } else {
        //       this.toaster.info('Algo ha sucedido, intente nuevamente 2', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        //     }
        //   }
        // });
  
        // await this.webService.insertCotization(url, cotization).then(async (rescotization: any) => {
        //   // //console.log("insertar", rescotization);
        //   if (rescotization.rta == true) {
        //     await this.webService.createMailBody(type, client, values, configuracion, shoppingCart, rescotization.res.id_cotizacion).then(async (resbody) => {
        //       await this.webService.sendMailService(resbody).then(async (resmail: any) => {
        //         // //console.log("Mail", resmail);
        //         await this.webService.sendNotificationAdministrator(url, this.notificationAdmin).then((resnotify) => { });
        //         let data = {
        //           status: 'Accepted',
        //           result: 'La transacción se ha realizado con éxito, en breve un asesor se comunicará para coordinar el despacho.',
        //           reference: rescotization.res.id_cotizacion
        //         }
        //         await this.TransactionResultModal(data);
        //       });
        //     });
        //   } else {
        //     if (rescotization.res.code == 0) {
        //       this.toaster.warning(rescotization.res.message, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        //     } else {
        //       this.toaster.info('Algo ha sucedido, intente nuevamente 2', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        //     }
        //   }
        // });
        this.loadingAll=false;

        await this.webService.postGeneral2(url+"insert_cotizacion", cotization).subscribe(async (rescotization: any) => {

          console.log("insertar", rescotization);
          if (rescotization.rta == true) {
            await this.webService.createMailBody(type, client, values, configuracion, shoppingCart, rescotization.id_cotizacion).then(async (resbody) => {
              await this.webService.sendMailService(resbody).then(async (resmail: any) => {
                // //console.log("Mail", resmail);
                // await this.webService.sendNotificationAdministrator(url, this.notificationAdmin).then((resnotify) => { });
                await this.webService.postGeneral2(url + "send_notification_all_client", this.notificationAdmin).subscribe((resnotify) => { });
                let data = {
                  status: 'Accepted',
                  result: 'La transacción se ha realizado con éxito, en breve un asesor se comunicará para coordinar el despacho.',
                  reference: rescotization.id_cotizacion
                }
                await this.TransactionResultModal(data);
              });
            });
          } else {
            if (rescotization.code == 0) {
              this.toaster.warning(rescotization.message, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            } else {
              this.toaster.info('Algo ha sucedido, intente nuevamente 3', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          }
        });
        // await this.webService.insertCotization(url, cotization).then(async (rescotization: any) => {
        //   // //console.log("insertar", rescotization);
        //   if (rescotization.rta == true) {
        //     await this.webService.createMailBody(type, client, values, configuracion, shoppingCart, rescotization.res.id_cotizacion).then(async (resbody) => {
        //       await this.webService.sendMailService(resbody).then(async (resmail: any) => {
        //         // //console.log("Mail", resmail);
        //         await this.webService.sendNotificationAdministrator(url, this.notificationAdmin).then((resnotify) => { });
        //         let data = {
        //           status: 'Accepted',
        //           result: 'La transacción se ha realizado con éxito, en breve un asesor se comunicará para coordinar el despacho.',
        //           reference: rescotization.res.id_cotizacion
        //         }
        //         await this.TransactionResultModal(data);
        //       });
        //     });
        //   } else {
        //     if (rescotization.res.code == 0) {
        //       this.toaster.warning(rescotization.res.message, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        //     } else {
        //       this.toaster.info('Algo ha sucedido, intente nuevamente 3', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        //     }
        //   }
        // });
      }
    // }
    

  
  }
  async generalPaymentButtonQR(type, client, values, cotization, configuracion, shoppingCart, url) {
    console.log('type',type);
    this.loadingAll=true;

    // if(!this.flagComprobante){
    //   this.toaster.warning('Es obligatorio subir el comprobante', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });

    // }else{
      if(type==9 ){
            if(!this.flagComprobante){
            //  this.toaster.warning('Es obligatorio subir el comprobante', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });

    }else{
        let randomC = this.fireService.generateRandomString();
        //Ruta Base de Datos
        let rutaC = `${this.empresa}/comprobantes/${randomC}_${this.comprobante.name  
        }`; 
        let rutaCBD = `comprobantes%2F${randomC}_${this.comprobante.name}`

        await this.fireService
        .uploadImage(this.comprobante, rutaC)
        .then((data: any) => {
        });
        cotization.cotizacion.tipo_pago_app = await rutaCBD;
      }

      await this.webService.postGeneral2(url+"insert_cotizacion", cotization).subscribe(async (rescotization: any) => {

        console.log("insertar AQUI ", rescotization);
        if (rescotization.rta == true) {
          this.loadingAll=false;

          await this.webService.createMailBody(type, client, values, configuracion, shoppingCart, rescotization.id_cotizacion).then(async (resbody) => {
            await this.webService.sendMailService(resbody).then(async (resmail: any) => {
              // //console.log("Mail", resmail);
              // await this.webService.sendNotificationAdministrator(url, this.notificationAdmin).then((resnotify) => { });
              await this.webService.postGeneral2(url + "send_notification_all_client", this.notificationAdmin).subscribe((resnotify) => { });
              let data = {
                status: 'Accepted',
                result: 'La transacción se ha realizado con éxito, en breve un asesor se comunicará para coordinar el despacho.',
                reference: rescotization.id_cotizacion
              }
              await this.TransactionResultModal(data);
            });
          });
        } else {
          this.loadingAll=false;

          if (rescotization.code == 0) {
            this.toaster.warning(rescotization.message, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          } else {
            this.toaster.info('Algo ha sucedido, intente nuevamente 4', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        }
      });
        // //console.log('cotizacion', cotization);
        // await this.webService.insertCotization(url, cotization).then(async (rescotization: any) => {
        //   // //console.log("insertar", rescotization);
        //   if (rescotization.rta == true) {
        //     await this.webService.createMailBody(type, client, values, configuracion, shoppingCart, rescotization.res.id_cotizacion).then(async (resbody) => {
        //       await this.webService.sendMailService(resbody).then(async (resmail: any) => {
        //         // //console.log("Mail", resmail);
        //         await this.webService.sendNotificationAdministrator(url, this.notificationAdmin).then((resnotify) => { });
        //         let data = {
        //           status: 'Accepted',
        //           result: 'La transacción se ha realizado con éxito, en breve un asesor se comunicará para coordinar el despacho.',
        //           reference: rescotization.res.id_cotizacion
        //         }
        //         await this.TransactionResultModal(data);
        //       });
        //     });
        //   } else {
        //     if (rescotization.res.code == 0) {
        //       this.toaster.warning(rescotization.res.message, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        //     } else {
        //       this.toaster.info('Algo ha sucedido, intente nuevamente 4', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        //     }
        //   }
        // });

      } 
    // }
  }

  // Whatsapp
  async whatsappPaymentButton(type, client, values, cotization, configuracion, shoppingCart, url) {
    let products = "";
    let text = "";
    let total = 0;
    let costoEnvio = 0;
    await this.webService.insertCotization(url, cotization).then(async (rescotization: any) => {
      if (rescotization.rta == true) {
        await this.webService.createMailBody(type, client, values, configuracion, shoppingCart, rescotization.res.id_cotizacion).then(async (resbody) => {
          await this.webService.sendMailService(resbody).then(async (resmail: any) => {
            // ////console.log("Mail", resmail);
            await this.webService.getTotalAndShoppingCost(values).then((restot: any) => {
              total = restot.total;
              costoEnvio = restot.costoEnvio;
            });
            let cont = 1;
            for (let p of shoppingCart) {
              products += '[' + cont + '] ' + p.pro_nom + ', Cant: ' + p.quantity + ', P.Unit: ' + p.precioReal + " ";
              cont++;
            }
            if (values.costoEnvio.rta == true) {
              text = "?text=Hola, mi nombre es: " + client.nombres + " " + client.apellidos
                + ", me interesan los productos de su tienda: " + products
                + " | Subtotal : " + (values.calculos.subtotal).toFixed(2)
                + " | Iva: " + (values.calculos.iva).toFixed(2)
                + " | Costo envío: " + costoEnvio
                + " | Descuento: " + values.calculos.descuento
                + " | *** Total a Pagar: " + (total).toFixed(2)
                + " *** | ENTREGA A DOMICILIO ( Nombre: " + client.EnvioDomicilio.nombres + " " + client.EnvioDomicilio.apellidos
                + ", Provincia: " + values.costoEnvio.data.nacional.provincia + ", Cantón: " + values.costoEnvio.data.nacional.canton
                + ", Dirección: " + client.EnvioDomicilio.direccion + ", Referencia: " + client.EnvioDomicilio.referencia_domicilio
                + ", Teléfono: " + client.EnvioDomicilio.celular + ").";
            } else {
              text = "?text=Hola, mi nombre es: " + client.nombres + " " + client.apellidos
                + ", me interesan los productos de su tienda: " + products
                + " | Subtotal : " + (values.calculos.subtotal).toFixed(2)
                + " | Iva: " + (values.calculos.iva).toFixed(2)
                + " | Descuento: " + values.calculos.descuento
                + " | *** Total a Pagar: " + (total).toFixed(2) + " ***.";
            }
            await this.webService.sendNotificationAdministrator(url, this.notificationAdmin).then((resnotify) => { });
            await this.webService.getInformacion().then((resinfo: any) => {
              window.open("https://wa.me/" + resinfo[0].whatsapp + text, "_blank");
            });
            let data = {
              status: 'Accepted',
              result: 'La transacción se ha realizado con éxito, en breve un asesor se comunicará para coordinar el despacho.',
              reference: rescotization.res.id_cotizacion
            }
            await this.TransactionResultModal(data);
          });
        });
      } else {
        if (rescotization.res.code == 0) {
          this.toaster.warning(rescotization.res.message, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        } else {
          this.toaster.info('Algo ha sucedido, intente nuevamente 5', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      }
    });
  }

  // PayPhone
  async validateAccountPayphoneModal(type, client, values, cotization, configuracion, shoppingCart, url) {
    this.payPhoneAccount.number = '';
    let payPhoneData;
    this.modalCtrl.open(this.ModalPayPhone, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      if (this.payPhoneAccount.number) {
        this.loadingAll = true;
        await this.getDataPaymentButton("PayPhone").then((respayphone) => {
          payPhoneData = respayphone;
        });
        if (this.payPhoneAccount.valid == true && this.payPhoneAccount.iso2 && this.payPhoneAccount.numberComplet) {
          let data = {
            number: this.payPhoneAccount.number,
            prefix: this.payPhoneAccount.dialCode
          }
          await this.webService.verifyPayphoneAccount(data, payPhoneData).then(async (resaccount: any) => {
            //console.log("resaccount", resaccount);
            if (resaccount.rta == true) {
              await this.payphonePaymentButton(type, client, values, cotization, configuracion, shoppingCart, url, payPhoneData, this.payPhoneAccount);
            } else {
              this.showTutorialPayphoneModal();
            }
          });
        } else {
          this.toaster.warning('Número de télefono no válido, verifique e intente nuevamenente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
        this.loadingAll = false;
      } else {
        this.toaster.warning('Por favor, Ingrese su número de celular', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    let bpp = document.getElementById("btn-valid-pphone");
    bpp.style.background = this.configuracion.colorPrincipal;
    bpp.style.color = this.configuracion.colorLetra;
  }

  async payphonePaymentButton(type, client, values, cotization, configuracion, shoppingCart, url, payPhoneData, payPhoneAccount) {
    await this.webService.observableStatusPayphone().subscribe(async (res: any) => {
      //console.log("suscrito status payphone", res);
      if (res.rta == true) {
        if (res.status == 'Pending') {
          //console.log("Esperar ...");
        }
        if (res.status == 'Approved') {
          clearInterval(execute);
          this.loadingAll = true;
          await this.webService.createMailBody(type, client, values, configuracion, shoppingCart, res.id_cotizacion).then(async (resbody) => {
            await this.webService.sendMailService(resbody).then(async (resmail: any) => {
              await this.webService.sendNotificationAdministrator(url, this.notificationAdmin).then((resnotify) => { });
              let data = {
                status: 'Accepted',
                result: 'La transacción se ha realizado con éxito, en breve un asesor se comunicará para coordinar el despacho.',
                reference: res.id_cotizacion
              }
              await this.TransactionResultModal(data);
            });
          });
          this.loadingAll = false;
        }
      } else {
        clearInterval(execute);
        if (res.status == 'Canceled') {
          let data = {
            status: 'Canceled',
            result: 'La transacción ha caducado ó el cliente la cancelo',
            reference: 0
          }
          this.deleteCotizacion(url, res.id_cotizacion, data, 'view');
        }
      }
    });
    await this.webService.insertCotization(url, cotization).then(async (rescotization: any) => {
      if (rescotization.rta == true) {
        let total = 0;
        await this.webService.getTotalAndShoppingCost(values).then((restot: any) => {
          total = this.webService.roundValue((restot.total.toFixed(2)) * 100);
        });
        let data = {
          phoneNumber: payPhoneAccount.number,
          countryCode: payPhoneAccount.dialCode,
          amount: total,
          AmountWithoutTax: total,
          ClientTransactionId: rescotization.res.id_cotizacion,
          storeId: payPhoneData.secret_key
        }
        await this.webService.sendTransactionPayphone(data, payPhoneData).then(async (restransaccion: any) => {
          //console.log("restransaccion", restransaccion);
          if (restransaccion.rta == true) {
            let obs = {
              rta: true,
              status: 'Pending',
              data: {},
              id_cotizacion: rescotization.res.id_cotizacion
            }
            await this.webService.addObservableStatusPayphone(obs);
            await this.checkTransactionPayphone(restransaccion.res.transactionId, rescotization.res.id_cotizacion, payPhoneData, this.webService);
            await this.waitTransactionPayphoneModal(restransaccion.res, rescotization.res.id_cotizacion, payPhoneData)
          } else {
            this.deleteCotizacion(url, rescotization.res.id_cotizacion, '', 'del');
          }
        });
      } else {
        if (rescotization.res.code == 0) {
          this.toaster.warning(rescotization.res.message, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        } else {
          this.toaster.info('Algo ha sucedido, intente nuevamente 6', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      }
    });
  }

  async checkTransactionPayphone(id_transaccion, id_cotizacion, payPhoneData, webService) {
    let time = 10000;
    execute = await setInterval(function () {
      webService.checkPayphoneStatus(id_transaccion, payPhoneData).then(async (rescheck: any) => {
        webService.manipulatePayphoneStatus(rescheck, id_cotizacion);
      });
    }, time);
  }

  async showTutorialPayphoneModal() {
    this.modalCtrl.open(this.ModalTutorialPayPhone, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async waitTransactionPayphoneModal(data, id_cotizacion, payphone) {
    this.modalCtrl.open(this.ModalWaitTransactionPayphone, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      if (result == "Cancel") {
        this.loadingAll = true;
        await this.webService.cancelTransactionPayphone(data.transactionId, payphone).then(async (rescancel: any) => {
          let obs = {
            rta: false,
            status: 'Canceled',
            data: data,
            id_cotizacion: id_cotizacion
          }
          await this.webService.addObservableStatusPayphone(obs);
        });
        this.loadingAll = false;
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // DataFast **** AUN PENDIENTE DE CAMBIAR A PRODUCCION Y PROBAR ****
  async datafastPaymentButton(type, client, values, cotization, configuracion, shoppingCart, url) {
    let datafastData;
    this.loadingAll = true;
    await this.getDataPaymentButton("DataFast").then((resdatafast) => {
      // //console.log("1ro resdatafast", resdatafast);
      datafastData = resdatafast;
    });
    await this.webService.insertCotization(url, cotization).then(async (rescotization: any) => {
      // //console.log("cotizacion", rescotization);
      if (rescotization.rta == true) {
        for (let prod of shoppingCart) {
          for (let det of cotization.detalle) {
            if (prod.id_producto == det.Producto_codigo) {
              prod.value_final = det.priceiva;
            }
          }
        }
        await this.webService.getPaymentIdentifierDatafast(datafastData, client, rescotization.res.id_cotizacion, shoppingCart, values).then(async (rescheck: any) => {
          // //console.log("2do rescheck", rescheck);
          if (rescheck.rta == true) {
            this.dataFast = {
              rta: false,
              id: rescheck.data.id,
              dominio: this.configuracion.dominioPagina + '/carrito?id_cotizacion=' + rescotization.res.id_cotizacion,
              id_cotizacion: '',
              path: ''
            }
            var script = document.createElement("script");

            script.type = "text/javascript";
            script.src = datafastData.url + 'v1/paymentWidgets.js?checkoutId=' + this.dataFast.id;

            document.onmouseout = function () {
              var holder = (<HTMLInputElement>document.getElementsByClassName('wpwl-control-cardHolder')[0]).value;
              var button = (<HTMLInputElement>document.getElementsByClassName('wpwl-button-pay')[0]).disabled;
                // //console.log("Btn deshabilitado", button);
              if (holder && button == false) {
                // //console.log("entraaa condicion");
                (<HTMLInputElement>document.getElementsByClassName('wpwl-button-pay')[0]).disabled = false;
                (<HTMLInputElement>document.getElementsByClassName('wpwl-button-pay')[0]).style.backgroundColor = '#5cb85c';
                (<HTMLInputElement>document.getElementsByClassName('wpwl-button-pay')[0]).style.borderColor = '#4cae4c';

                (<HTMLInputElement>document.getElementsByClassName('wpwl-label-cardHolder')[0]).style.color = '#fff';
                (<HTMLInputElement>document.getElementsByClassName('wpwl-label-cardHolder')[0]).innerHTML = "Card holder";

                (<HTMLInputElement>document.getElementsByClassName('wpwl-control-cardHolder')[0]).style.border = '1px Solid #ccc';
              } else {
                if (!holder) {
                  (<HTMLInputElement>document.getElementsByClassName('wpwl-button-pay')[0]).disabled = true;
                  (<HTMLInputElement>document.getElementsByClassName('wpwl-button-pay')[0]).style.backgroundColor = '#d9534f';
                  (<HTMLInputElement>document.getElementsByClassName('wpwl-button-pay')[0]).style.borderColor = '#d43f3a';

                  (<HTMLInputElement>document.getElementsByClassName('wpwl-label-cardHolder')[0]).style.color = '#a94442';
                  (<HTMLInputElement>document.getElementsByClassName('wpwl-label-cardHolder')[0]).innerHTML = "Invalid card holder";

                  (<HTMLInputElement>document.getElementsByClassName('wpwl-control-cardHolder')[0]).style.border = '1px Solid #a94442';
                }
              }
            }

            this.elementRef.nativeElement.appendChild(script);

            this.modalCtrl.open(this.ModalDatafast, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
              // //console.log("result", result);
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
              //console.log("Cancelar", reason);
              let data = {
                status: 'Canceled',
                result: 'La transacción ha sido cancelada por el cliente',
                reference: 0
              }
              this.deleteCotizacion(url, rescotization.res.id_cotizacion, data, 'view');
            });
          } else {
            this.deleteCotizacion(url, rescotization.res.id_cotizacion, '', 'del');
          }
        });
      } else {
        if (rescotization.res.code == 0) {
          this.toaster.warning(rescotization.res.message, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        } else {
          this.toaster.info('Algo ha sucedido, intente nuevamente 7', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      }
    });
    this.loadingAll = false;
  }

  async checkTransactionDatafast(datafast) {
    this.loadingAll = true;
    let datafastData;
    let values;
    let url;
    // Obteer datos de DataFast
    await this.getDataPaymentButton("DataFast").then((resdatafast) => {
      // //console.log("boton", resdatafast);
      datafastData = resdatafast;
    });
    // Recalcular valores obteniendo productos de cotizacion y datos de envio almacenados en Local storage
    await this.calculateValuesOrder(5, this.user).then((resvalues: any) => {
      values = resvalues;
      // //console.log("Usuario con data adicional", this.user);
    });
    // Obtener url de la empresa
    await this.webService.getUrlEmpresa().then((url_billing) => {
      url = url_billing;
    });
    await this.webService.getStatusDatafast(this.dataFast.path, datafastData).then(async (rescheck: any) => {
      this.webService.deleteDeliveryData(this.configuracion.localStorage).then((res) => { });
      if (rescheck.rta == true) {
        let code = rescheck.res.result.code;
        if (code == '000.100.112' || code == '000.000.000') {
          //console.log("enviarrrr correos");
          await this.sendMailBuy(6, this.user, values, this.configuracion, this.shoppingCart, this.dataFast.id_cotizacion, url, this.notificationAdmin).then((res) => { });
        } else {
          await this.webService.checkDatafastStatus(this.dataFast.id_cotizacion, datafastData).then(async (resstatus: any) => {
            //console.log("Codigo de error", resstatus);
            if (resstatus.response.rta == false) {
              this.deleteCotizacion(url, this.dataFast.id_cotizacion, resstatus.response.message, 'view');
            } else {
              await this.sendMailBuy(6, this.user, values, this.configuracion, this.shoppingCart, this.dataFast.id_cotizacion, url, this.notificationAdmin).then((res) => { });
            }
          });
        }
      } else {
        //console.log("staus resourcePath", rescheck.res.result);
        await this.webService.checkDatafastStatus(this.dataFast.id_cotizacion, datafastData).then(async (resstatus: any) => {
          //console.log("Error consulta", resstatus);
          if (resstatus.response.rta == false) {
            this.deleteCotizacion(url, this.dataFast.id_cotizacion, '', 'del');
          } else {
            await this.sendMailBuy(6, this.user, values, this.configuracion, this.shoppingCart, this.dataFast.id_cotizacion, url, this.notificationAdmin).then((res) => { });
          }
        });
      }
    });
    this.loadingAll = false;
  }

  // Cuando regresa de datafast volver a calcular valores de compra
  async calculateValuesOrder(type, client) {
    let values;
    await this.webService.getDeliveryData(this.configuracion.localStorage).then(async (resdel: any) => {
      // //console.log("valores ls", resdel);
      let deliveryData = resdel.data;
      if (resdel.rta == true) {
        // Agregar datos de LS al cliente para correo
        this.user.EnvioDomicilio = deliveryData.client.EnvioDomicilio;
        this.user.bankAccountData = deliveryData.client.bankAccountData;
        await this.webService.determinateArancelsAndDiscounts(type, this.configuracion, deliveryData).then(async (resarancel: any) => {
          await this.webService.calculateSeparateSubtotal(resarancel, this.shoppingCart).then(async (ressubtot) => {
            resarancel.calculos = ressubtot;
            // Aqui llamar funcion de crear cotizacion
            await this.webService.generateDataCotization(this.shoppingCart, deliveryData.client, resarancel, this.configuracion, type).then(async (rescotization: any) => {
              // Fin de llamar funcion de crear cotizacion
              if (resarancel.costoEnvio.rta == true) {
                await this.webService.calculateShippingCostTotal(resarancel).then((restotal: any) => {
                  resarancel.subtotalAux = restotal.total;
                });
              } else {
                resarancel.subtotalAux = 0;
              }
              values = resarancel;
            });
          });
        });
      } else {
        this.webService.goHome();
        this.toaster.error('No se ha podido verificar la transacción, Intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
    return values;
  }

  async sendMailBuy(type, user, values, configuracion, shoppingCart, id_cotizacion, url, notificationAdmin) {
    await this.webService.createMailBody(type, user, values, configuracion, shoppingCart, id_cotizacion).then(async (resbody) => {
      await this.webService.sendMailService(resbody).then(async (resmail: any) => {
        await this.webService.sendNotificationAdministrator(url, notificationAdmin).then((resnotify) => { });
        let data = {
          status: 'Accepted',
          result: 'La transacción se ha realizado con éxito, en breve un asesor se comunicará para coordinar el despacho.',
          reference: this.dataFast.id_cotizacion
        }
        await this.TransactionResultModal(data);
      });
    });
  }

  async payPalPaymentButton(type, client, values, cotization, configuracion, shoppingCart, url) {

    let paypalData;
    let items = [];
    let totalPagar;
    await this.getDataPaymentButton("PayPal").then((respaypal) => {
      //console.log("respaypal", respaypal);

      paypalData = respaypal;
    });

    for (let prod of shoppingCart) {
      for (let det of cotization.detalle) {
        if (prod.id_producto == det.Producto_codigo) {
          prod.value_final = det.priceiva;
        }
      }
    }

    await this.webService.createDataPaypal(shoppingCart, values).then((respaypal: any) => {
      //console.log("respaypal", respaypal);
      items = respaypal.items;
      totalPagar = respaypal.totalPagar;
    });

    this.payPalConfig = {
      currency: 'USD',
      clientId: paypalData.token,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [{
          description: "Identificador de la transacción",
          amount: {
            currency_code: 'USD',
            value: totalPagar,
            breakdown: {
              item_total: {
                currency_code: 'USD',
                value: totalPagar,
              }
            }
          },
          items: items
        }]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'horizontal'
      },
      onApprove: async (data, actions) => {
        // //console.log('onApprove - transaction was approved, but not authorized', data, actions);
        this.loadingAll = true;
        await this.webService.insertCotization(url, cotization).then(async (rescotization: any) => {
          if (rescotization.rta == true) {
            await this.webService.createMailBody(type, client, values, configuracion, shoppingCart, rescotization.res.id_cotizacion).then(async (resbody) => {
              await this.webService.sendMailService(resbody).then(async (resmail: any) => {
                // //console.log("Mail", resmail);
                await this.webService.sendNotificationAdministrator(url, this.notificationAdmin).then((resnotify) => { });
                this.modalCtrl.dismissAll();
                let data = {
                  status: 'Accepted',
                  result: 'La transacción se ha realizado con éxito, en breve un asesor se comunicará para coordinar el despacho.',
                  reference: rescotization.res.id_cotizacion
                }
                await this.TransactionResultModal(data);
              });
            });
          } else {
            if (rescotization.res.code == 0) {
              this.toaster.warning(rescotization.res.message, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            } else {
              this.toaster.info('Algo ha sucedido, comuniquese con el adminisrador', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          }
        });
        this.loadingAll = false;
      },
      onClientAuthorization: async (data) => {
        // //console.log("Que nota mi rey");
        // //console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        //console.log("Status", data.status);

      },
      onCancel: (data, actions) => {
        // CIERRA EL MODAL
        this.modalCtrl.dismissAll();
        //console.log('OnCancel, Close Modal', data, actions);
      },
      onError: err => {
        // ERRORR
        this.modalCtrl.dismissAll();
        //console.log('OnError', err);
        this.toaster.error('No se ha podido crear la transacción, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      },
      onClick: (data, actions) => {
        // ACCEDE A UNA OPCION DEL TIPO DE PAGO
        // //console.log('onClick', data, actions);
      },
    };

  }

  // type 'del' = Solo eliminar
  // type 'view' = Eliminar y mostrar modal
  async deleteCotizacion(url, id_cotizacion, message, type) {
    this.loadingAll = true;
    this.webService.deleteCotization(url, id_cotizacion).then(async (resdel: any) => {
      this.loadingAll = false;
      if (resdel.rta == true) {
        if (type == 'view') {
          await this.TransactionResultModal(message);
        }
        if (type == 'del') {
          this.toaster.error('No se ha podido crear la transacción, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          this.webService.goShoppingCart();
        }
      } else {
        this.toaster.error('No se ha podido eliminar el pedido', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  // Mostrar el resultado de las transacciones
  async TransactionResultModal(data) {
    
    this.modalCtrl.dismissAll();
    this.transaction = {
      status: data.status,
      result: data.result,
      reference: data.reference
    }
    this.modalCtrl.open(this.ModalTransactionResult, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      await this.emptyShoppingCart(this.user);
      if(this.information.esPuntoVenta==1){
      localStorage.setItem('carCount','0');
      this.webService.signOuth(this.configuracion.loginStorage).then(async (resClose: any) => {
      this.router.navigateByUrl('catalogo');
    });
      }else{
        await this.webService.goHome();

      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    // let bro = document.getElementById('btn-result-ok');
    // bro.style.background = this.configuracion.colorPrincipal;
    // bro.style.color = this.configuracion.colorLetra;
  }

  async getDataPaymentButton(entity) {
    let data;
    await this.webService.getPaymentButtons().then((res: any) => {
      for (let e of res) {
        if (e.nombre == entity) {
          data = e;
        }
      }
    });
    return data;
  }

  // Funciones Obtener el codigo y numero celular dependiendo del pais
  getNumber($event) {
    this.payPhoneAccount.numberComplet = $event;
  }

  // Obtener el codigo del pais
  onCountryChange($event) {
    this.payPhoneAccount.iso2 = $event.iso2;
  }

  hasError($event) {
    this.payPhoneAccount.valid = $event;
  }

  telInputObject($event) {
    this.payPhoneAccount.iso2 = $event.s.iso2;
    this.payPhoneAccount.dialCode = $event.s.dialCode;
  }
  // Fin Obtener el codigo y numero celular dependiendo del pais
  onSelect(event) {
  
    if (event.target.files.length > 0) {
      this.comprobante = event.target.files[0];
      //console.log(this.comprobante);
      if (this.comprobante.type.startsWith("application/")) {
        // Image file detected, display error message
        this.toaster.error('Sólo se permiten archivos de imagen. Por favor, seleccione un formato diferente.', '', {
          timeOut: 4000,
          positionClass: 'toast-bottom-full-width',
          closeButton: true,
          progressBar: true
        });
        this.comprobante = {};
        //console.log(this.comprobante);
        // Object.keys
        if (Object.keys(this.comprobante).length === 0) {
            this.flagComprobante = false;
        }
       
      } else {
        this.flagComprobante = true;
        // this.createAccountForm.controls['documentoExportador'].setValue(this.certificadoExportador.name);
      }
    }
  }
  
  // onRemove(event) {
  //   //console.log(event);
  //   this.files.splice(this.files.indexOf(event), 1);
  // }
  printTicket(){
  
  }

  returnTO(site){
    this.router.navigateByUrl(site);
  }

  // ==================================================== Generar comanda ====================================================
  formComanda= new FormGroup({
    contacto:new FormControl('', Validators.required ),
    pago:new FormControl(),
    principal:new FormControl(''),
    secundaria:new FormControl(''),
    referencia:new FormControl(''),
})

// Funciones Varias
totalComanda(prods){
  let total = 0;
  for(let prod of prods){
    total += prod.valor*prod.cantidad;
  }
  return total
}
totalComanda2(prods){
  let total = 0;
  for(let prod of prods){
    total += prod.precioReal*prod.quantity;
  }
  return total
}
totalIvaComanda(prods){
  let iva = 0;
  for(let prod of prods){
    iva += (prod.valor*prod.cantidad) - ((prod.valor*prod.cantidad)/1.15);
  }
  return iva
}
numberFourDecimal(numero: number): number {
  let resul = Number(numero.toFixed(4));
  return resul;
}

modificarURL(url: string): string {
  const partes = url.split('/');
  if (partes.length > 4) {
    return partes.slice(0, 4).join('/') + '/';
  }  
  return url;
}

  abrirComanda(dataClient){
    console.log('Datos del cliente', dataClient);
    this.webService.getGeneral2(this.url+'pventa_granel/api_restaurant/init_ecommerce').subscribe((data:any)=>{
      console.log('result ==>', data);
      if(data.rta){
        this.flagMakeComanda=true;
        this.id_mesa=data.mesa.mesa_id;
        this.id_mesero=data.mesero.id;
      this.webService.getGeneral2(this.url+'pventa_granel/api_restaurant/tipos_pago').subscribe((data2:any)=>{
        console.log('result tiposPAGO', data2);
          this.tiposPago=data2.formaspago;
          this.formComanda.controls['pago'].setValue(this.tiposPago[0].cod);
      })

      this.closeModal();
      this.formComanda.controls['contacto'].setValue(dataClient.razonsocial);
      this.formComanda.controls['principal'].setValue(dataClient.direccion);
      this.formComanda.controls['secundaria'].setValue(dataClient.direccion);
      this.formComanda.controls['referencia'].setValue(dataClient.referencia_domicilio);
      // this.openMODALNew();zoom
      this.modalCtrl.open(this.modalCarritoLocal, {size:'xl', backdrop: 'static', keyboard: false, centered:true})
  
      }else{
        this.toaster.error(`${data.msg}`, '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
  })
}

closeModal(){
  this.modalCtrl.dismissAll();
}
closeModal1(){
  cerrarModal1('#modalRC');        
}

comandaFinal(form){
 let prods = this.shoppingCart;
 this.prods = prods;

  console.log('prods ==>', this.prods);
  
  if (this.loadingAll) {
    return;
  }
  this.banderaAC = true;
  this.loadingAll=true;
  let product = {
    "id_producto": "",
    "cantidad": 0,
    "valor": 0.0,
    "detalle": ""
  }
  let detalle_comanda = [];
  for(let prod of this.prods){
    product = {
      "id_producto":  prod.id_producto,
      "cantidad": prod.quantity,
      "valor": prod.precioReal,
      "detalle": prod.detalle
    }
    // product.id_producto = prod.id_producto,
    // product.cantidad = prod.cantidad,
    // product.valor = prod.valor,
    // product.detalle = prod.detalle
    detalle_comanda.push(product);
  }
 this.prods= detalle_comanda;
 console.log('PRODS === >', this.prods);
 

  console.log('ASI QUEDA',detalle_comanda);
  this.contactoFinal= form.contacto;
  let comanda = {
  	"id_mesero": this.id_mesero,
		"user": this.id_mesero,
		"subtotal":this.numberFourDecimal( this.totalComanda(detalle_comanda)),
		"subtotal0": "0.00",
		"subtotal12":this.numberFourDecimal(this.totalComanda(detalle_comanda) - this.totalIvaComanda(detalle_comanda)),
		"servicios":this.numberFourDecimal(this.totalComanda(detalle_comanda) - this.totalIvaComanda(detalle_comanda)),
		"iva12": this.numberFourDecimal(this.totalIvaComanda(detalle_comanda)) ,
		"estado": 2,
		"observaciones": "Domicilio",
		"tipoprecio": "pA",
		"cliente": this.addressDeliveryData.client.PersonaComercio_cedulaRuc,
		"id_mesa": this.id_mesa,
		"contacto": form.contacto,
		"pago": form.pago,
		"principal": form.principal,
		"secundaria": form.secundaria,
		"referencia": form.referencia
  }
  let json = {
    comanda:comanda,
    detalle_comanda: detalle_comanda
  }

  this.webService.postGeneral2(this.url+'pventa_granel/api_restaurant/insert_comanda_ecommerce', json).subscribe((data:any)=>{
    console.log('data',data);
    // this.resultadoComanda = data;
    if(data.rta){
      this.toaster.success('Pedido generado exitosamente ', '', { timeOut: 5000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      this.modalCtrl.dismissAll();
      this.banderaAC = false;
      this.loadingAll = false;
      this.idComanda= data.nro_comanda;
      this.modalCtrl.open(this.modalPrintComanda, {size:'sm', backdrop: 'static', keyboard: false, centered:true})
    }else{
      this.toaster.error(`Algo paso, comuniquese con el administrador del sistema.`, '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }    
  })

  console.log('comanda', json);
  
}

 async closeFinal(){
  this.formComanda.reset();
  this.closeModal();

  
  await this.emptyShoppingCart(this.user);
      if(this.information.esPuntoVenta==1){
      localStorage.setItem('carCount','0');
      this.webService.signOuth(this.configuracion.loginStorage).then(async (resClose: any) => {
      this.router.navigateByUrl('catalogo');
    });
  
}
}

changeDetalle(event, prod){
  console.log('event', event.target.value);
  prod.detalle=event.target.value;
  
  console.log('PRODS COMO QUEDA ==> ', this.shoppingCart);
  

}


openMODALNew(){
  abrirModalCodigo('#modalRC');
}
}


