import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';


import { ServicesService } from '../../../shared/services/services.service';

@Component({
  selector: 'app-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.scss']
})
export class LoginUserComponent implements OnInit {

  @ViewChild("registerUserModal", {}) registerUserModal: TemplateRef<any>;
  @ViewChild("modalRecoverPassword", {}) recoverPasswordModal: TemplateRef<any>;


  public closeResult: string;
  public loadingAll = false;
  public configuracion;
  public login = {
    usuario: '',
    clave: '',
    viewPass: true
  }
  public newClient = {
    cedula: '',
    nombres: '',
    apellidos: '',
    razonsocial: '',
    direccion: '',
    telefonos: '',
    email: '',
    celular: '',
    tipoCli: '1',
    vendedor_id: '',
    diasCredito: '0',
    cupo_credito: '0',
    es_pasaporte: 0
  }
  public recover = {
    cedula: '',
    correo: ''
  }
  public existCliente = false;
  information:any;
  constructor(
    private modalCtrl: NgbModal,
    private webService: ServicesService,
    private toaster: ToastrService
  ) { }

  async ngOnInit() {
    await this.getInformacion();
    await this.getConfiguracion();
 
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === ModalDismissReasons) {
      return `with: ${reason}`;
    }
  }

  async getConfiguracion() {
    await this.webService.getConfiguracion().then(async (dataConf: any) => {
      this.configuracion = dataConf[0];
      console.log('configuracion',  this.configuracion );
      
      // let color  = this.information.colorLetraSlogan;
      let colorLetra  = this.configuracion.colorLetra;
      let colorLetraSecundario  = this.configuracion.colorLetraSecundario;
      let color  = this.configuracion.colorPrincipal;
      let colorSecond  = this.webService.hexToRgba(color, 0.1);
      document.documentElement.style.setProperty('--dynamic-color', colorLetra);
      document.documentElement.style.setProperty('--ligther-tone', colorSecond);
      document.documentElement.style.setProperty('--color-letter-primary', colorLetra);
      document.documentElement.style.setProperty('--color-letter-secondary', colorLetraSecundario);
      console.log('colores', colorLetra);
      console.log('colores', colorLetraSecundario);
      console.log('colores', color);
      console.log('colores', colorSecond);
      

    });
  }

  async getInformacion() {
    await this.webService.getInformacion().then((resinfo: any) => {
      console.log(resinfo);
      this.information=resinfo[0];
      console.log('information.mision',this.information);
      
     

    });
  }

  async dismissModal(data) {
    if (data == 'Login') {
      let url;
      await this.webService.getUrlEmpresa().then(async (url_billing) => {
        url = url_billing
      });
      this.login.clave = this.login.usuario;
      console.log('Entra aqui', this.existCliente);
      console.log('Info login aqui', this.login);
      
      if (this.existCliente == true) {
        this.modalCtrl.dismissAll(data);
        await this.loginClient(url, this.configuracion, this.login, 'login');
      } else {
        console.log("validar");
        await this.validateClientExist(url, this.login.usuario);

        console.log("vino de validar", this.existCliente);
      }
    }
    if (data == 'Registrar') {
      this.modalCtrl.dismissAll(data);
      await this.registerClient();
    }
    if (data == 'Recover') {
      this.modalCtrl.dismissAll(data);
      await this.modalRecoverPassword();
    }
    if (data == 'Close') {
      this.modalCtrl.dismissAll(data);
    }
  }

  async validateClientExist(url, cedula) {
    this.loadingAll = true;
    await this.webService.getCustomerDataByCedula(url, cedula).then(async (resvalidate: any) => {
      if (!resvalidate.error) {
        if (resvalidate.length > 0) {
          this.existCliente = true;
          let login:any;
          console.log('this.information.esPuntoVenta',this.information.esPuntoVenta);
          
          if(this.information.esPuntoVenta==0 ){
             login={
              'usuario':cedula,
              'clave':this.login.clave,
          }
          }else{
             login={
              'usuario':cedula,
              'clave':cedula,
          }
          }
          console.log('login', login);
          

          await this.loginClient(url, this.configuracion, login, 'login');
          console.log('entra cuando meto cédula');
          
          await this.toaster.success('Ingrese su contraseña, Por defecto es su Cédula / Ruc', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true, titleClass: 'toast-css' });
          // await document.getElementById('inp-password').focus();
        } else {
          this.existCliente = false;
          this.toaster.warning('Usuario no encontrado, Registrelo.', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          this.dismissModal('Registrar');
        }
      } else {
        this.modalCtrl.dismissAll({});
        this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
    this.loadingAll = false;
  }

  async loginClient(url_billing, configuracion, login, type) {
    if (login.usuario && login.usuario) {
      if (type == 'login') {
        this.toaster.info('Espere un momento ...', '', { timeOut: 1000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
      await this.webService.loginCliente(url_billing, login, 'login').then(async (resclient: any) => {
        if (!resclient.error) {
          if (resclient.rta == true) {
            await this.webService.saveUserLocalStorage(resclient.data[0], configuracion.loginStorage, "Client").then(async (resauth: any) => {
              await this.webService.getproductsCart({ id_cliente: resauth.data.PersonaComercio_cedulaRuc }).then(async (resprod: any) => {
                if (resprod.rta == true) {
                  let observable = {
                    number: resprod.data.length,
                    total: await this.webService.calculateTotalCartProducts(resprod.data)
                  }
                  this.webService.shopcart$.next(observable);
                }
              });
              await this.webService.refreshPage(configuracion);
              this.toaster.success('Bienvenid@, ' + resclient.data[0].nombres, '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            });
          } else {
            if (resclient.code == 406) {
              this.toaster.warning('Contraseña incorrecta, Intente nuevamente o Proceda a recuperarla', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              this.dismissModal('Recover');
            } else {
              this.toaster.warning('Usuario no encontrado, Registrelo.', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              this.dismissModal('Registrar');
            }
          }
        } else {
          this.toaster.error('Ha ocurrido un error, Intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    } else {
      this.toaster.warning('Ingrese sus credenciales', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async registerClient() {
    this.modalCtrl.open(this.registerUserModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      let url_billing;
      let client = result;
      if (client.cedula && client.nombres && client.apellidos && client.email) {
        if (client.cedula.length <= 13) {
          this.toaster.info('Espere un momento ...', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          await this.webService.getUrlEmpresa().then(async (url) => {
            url_billing = url;
          });
          let url = url_billing + "validar_cedula?ci=" +  client.cedula;

          await this.webService.getGeneral2(url).subscribe(async (resValid: any) => {
            console.log('REGISTRER ', resValid);
            
            // if (resValid.valor==1) {
              if (this.webService.validateEmail(client.email) == true) {
                client.nombres = this.webService.convertMayuscula(client.nombres);
                client.apellidos = this.webService.convertMayuscula(client.apellidos);
                client.razonsocial = client.nombres + ' ' + client.apellidos;
                if (resValid.valor == 0) {
                  client.es_pasaporte = 1;
                }
                (await this.webService.registerClientBilling(url_billing, client)).subscribe(async (resRegist: any) => {
                  console.log('registter ' ,resRegist);
                  
                  // if (!resValid.error) {
                    if (resRegist.rta) {
                      let data = {
                        nombres: client.nombres,
                        apellidos: client.apellidos,
                        cedula: client.cedula,
                        email: client.email
                      }
                      await this.webService.createBodyMailRegister(this.configuracion, data).then(async (resbody) => {
                        await this.webService.sendMailService(resbody).then((resmail: any) => {
                          // console.log(resmail);
                        });
                      });
                      let login = {
                        usuario: client.cedula,
                        clave: client.cedula
                      }
                      console.log( 'linea', login);
                      
                      await this.loginClient(url_billing, this.configuracion, login, 'register');
                    } else {
                      this.toaster.warning('El cliente ya se encuentra regitrado, Inicie sesión', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                    }
                  // } else {
                  //   this.toaster.error('Error al conectar con el servidos, vualva a intentarlo', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                  // }
                });
              } else {
                this.toaster.error('Correo electrónico no válido', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                this.dismissModal('Registrar');
              }
            // } else {
            //   this.toaster.error('Error al conectar con el servidos, vualva a intentarlo', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            // }
          });
          // await this.webService.validateCi(url_billing, client.cedula).then(async (resValid: any) => {
          //   if (!resValid.error) {
          //     if (this.webService.validateEmail(client.email) == true) {
          //       client.nombres = this.webService.convertMayuscula(client.nombres);
          //       client.apellidos = this.webService.convertMayuscula(client.apellidos);
          //       client.razonsocial = client.nombres + ' ' + client.apellidos;
          //       if (resValid.valor == 0) {
          //         client.es_pasaporte = 1;
          //       }
          //       this.webService.registerClientBilling(url_billing, client).then(async (resRegist: any) => {
          //         console.log('registter ' ,resRegist);
                  
          //         if (!resValid.error) {
          //           if (resRegist.res.rta == 1) {
          //             let data = {
          //               nombres: client.nombres,
          //               apellidos: client.apellidos,
          //               cedula: client.cedula,
          //               email: client.email
          //             }
          //             await this.webService.createBodyMailRegister(this.configuracion, data).then(async (resbody) => {
          //               await this.webService.sendMailService(resbody).then((resmail: any) => {
          //                 // console.log(resmail);
          //               });
          //             });
          //             let login = {
          //               usuario: client.cedula,
          //               clave: client.cedula
          //             }
          //             console.log( 'linea', login);
                      
          //             await this.loginClient(url_billing, this.configuracion, login, 'register');
          //           } else {
          //             this.toaster.warning('El cliente ya se encuentra regitrado, Inicie sesión', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          //           }
          //         } else {
          //           this.toaster.error('Error al conectar con el servidos, vualva a intentarlo', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          //         }
          //       });
          //     } else {
          //       this.toaster.error('Correo electrónico no válido', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          //       this.dismissModal('Registrar');
          //     }
          //   } else {
          //     this.toaster.error('Error al conectar con el servidos, vualva a intentarlo', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          //   }
          // });
        } else {
          this.toaster.error('El número de idenficación no puede poseer más de 13 caracteres.', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          this.dismissModal('Registrar');
        }
      } else {
        this.toaster.error('Campos vacios, ingrese su información', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        this.dismissModal('Registrar');
      }

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log(reason);
    });

  }

  async modalRecoverPassword() {
    let userPasword = '';
    let dataUpdate: any;
    this.modalCtrl.open(this.recoverPasswordModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      this.toaster.info('Espere un momento ...', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      if (this.recover.cedula && this.recover.correo) {
        if (this.webService.validateEmail(this.recover.correo) == true) {
          await this.webService.getUrlEmpresa().then(async (url_billing) => {
            await this.webService.validateClientCedIdentity(url_billing, this.recover).then(async (resvalid: any) => {
              if (!resvalid.error) {
                if (resvalid.rta == true) {
                  if (resvalid.data.clave) {
                    userPasword = resvalid.data.clave;
                  } else {
                    userPasword = resvalid.data.PersonaComercio_cedulaRuc;
                    dataUpdate = {
                      tabla: 'billing_cliente',
                      id: 'PersonaComercio_cedulaRuc',
                      valor_id: resvalid.data.PersonaComercio_cedulaRuc,
                      atributo: 'clave',
                      valor_atributo: userPasword
                    }
                  }
                  await this.webService.createBodyMailRecoveryPassword(this.configuracion, resvalid.data, userPasword).then(async (resrecovery: any) => {
                    await this.webService.sendMailService(resrecovery).then(async (resmail: any) => {
                      if (resmail.rta == true) {
                        if (!resvalid.data.clave) {
                          await this.webService.updateAttributeTable(url_billing, dataUpdate).then((resupd: any) => {
                            if (!resupd.error) {
                              this.toaster.success('La contraseña ha sido envida a su correo', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                            } else {
                              this.toaster.error('Ha ocurrido un error al actualizar, Intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                            }
                          });
                        } else {
                          this.toaster.success('La contraseña ha sido envida a su correo', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                        }
                      } else {
                        this.toaster.error('No se ha podido enviar el correo, Intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                      }
                    });
                  });
                } else {
                  this.toaster.warning('Datos incorrectos, verifique e intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                }
              } else {
                this.toaster.error('Intente nuevamente', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              }
            });
          });
        } else {
          this.toaster.warning('Formato de Correo electrónico no válido', ' ', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      } else {
        this.toaster.warning('Ingrese su información e intente nuevamente', 'Campos vacios', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log(reason);
    });
    let brcv = document.getElementById('btn-recover');
    brcv.style.background = this.configuracion.colorPrincipal;
    brcv.style.color = this.configuracion.colorLetra;
  }

}
