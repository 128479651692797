import { Injectable } from "@angular/core";
import { User } from "../../models/user";

const TABLE_USER = "users"

@Injectable()
export class UserRepository {



    getAll():User[]{
        var data = localStorage.getItem(TABLE_USER);
        if (data) {
            return <User[]>(JSON.parse(data));
        }
        else 
            return [];
    }

    save(users :User[]){
        localStorage.setItem(TABLE_USER,JSON.stringify(users));
    }

}